import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IconButton, LinearProgress, Stack, TextField, Theme, Typography, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-multi-lang';
import { useEffect, useState } from 'react';
import { PasswordGenerationService } from '../../core/security';
import { useDispatch } from 'react-redux';
import { displayNotification } from '../../core/store/features/notification/notificationSlice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface SetPasswordDialogProps {
  open: boolean;
  isMobile: boolean;
  title: string;
  okLabel: string;
  cancelLabel: string;
  onClose: (e: any) => void;
}

export default function SetPasswordDialog(props: SetPasswordDialogProps) {

  const { onClose, open } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const styles = makeStyles(theme);
  const t = useTranslation();

  const [password, setPassword] = useState("");
  const [passwordStrenght, setPasswordStrenght] = useState(0);

  const [passwordError, setPasswordError] = useState("");


  useEffect(() => {
    validateForm();
  }, [password])


  // Validation functions
  const validateForm = () => {
    let isValid = true;

    if (!password) {
      setPasswordError("* Required");
      isValid = false;
    } else {
      if (password && password.length < 6) {
        setPasswordError("* minimum number of characters is 6");
      }
      else {
        setPasswordError("");
      }
    }

    validatePassword(password);

    return isValid;
  };

  function validatePassword(password: string) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        setPasswordStrenght(0);
        break;

      case 1:
        setPasswordStrenght(25);
        break;

      case 2:
        setPasswordStrenght(50);
        break;

      case 3:
        setPasswordStrenght(75);
        break;

      case 4:
        setPasswordStrenght(100);
        break;
    }
  }

  function handlePasswordChange(event: any) {
    setPassword(event.target.value);
  }

  async function handlePasswordGenerate(event: any) {
    const passwordGenerationService = new PasswordGenerationService();
    let newPassword = await passwordGenerationService.generatePassword({
      length: 14,
      ambiguous: false,
      number: true,
      minNumber: 1,
      uppercase: true,
      minUppercase: 0,
      lowercase: true,
      minLowercase: 0,
      special: true,
      minSpecial: 1,
      type: "password",
      numWords: 3,
      wordSeparator: "-",
      capitalize: false,
      includeNumber: false
    })
    setPassword(newPassword);
  }

  function handleCopyPassword(event: any) {
    navigator.clipboard.writeText(password);
    dispatch(displayNotification({
      message: "Ok",
      severity: "success"
    }));
  }

  const handleOkClose = () => {
    onClose({
      password: password
    });
  };

  const handleCancelClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography sx={styles.title} align='center'>{props?.title}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          height: 150
        }}
      >
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            height: 100
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={1}
          >
            <TextField
              label={t('Password')}
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              error={!!passwordError}
              helperText={passwordError}
            />

            <IconButton
              sx={styles.generatePasswordButton}
              onClick={handlePasswordGenerate}
            >
              <RefreshIcon />
            </IconButton>

            <IconButton
              sx={styles.generatePasswordButton}
              onClick={handleCopyPassword}
            >
              <ContentCopyIcon />
            </IconButton>
          </Stack>

          <LinearProgress
            color="primary"
            sx={styles.progressbar}
            value={passwordStrenght}
            variant="determinate"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button color="black" variant='contained' size="small" onClick={handleCancelClose}>{props?.cancelLabel || 'Cancel'}</Button>
        <Button variant='contained' size="small" onClick={handleOkClose}>{props?.okLabel || 'Ok'}</Button>
      </DialogActions>
    </Dialog>
  );
}

const makeStyles = (theme: Theme) => {
  return {
    title: {
      fontSize: '24px',
      fontWeight: 900,
      lineHeight: '30px'
    },
    card: {
      width: '248px',
      minHeight: '160px',
      padding: '16px 16px 8px 16px',
      borderRadius: '4px',
      color: theme.palette.black.main,
      background: theme.palette.greyLight.main
    },
    labelBold: {
      fontSize: {
        xs: '14px',
        sm: '14px',
        md: '18px',
        lg: '18px',
        xl: '18px',
      },
      fontWeight: 700,
      lineHeight: '27px'
    },
    actions: {
      width: '100%'
    },
    progressbar: {
      width: '100%'
    },
    generatePasswordButton: {
      background: theme.palette.greyLight.main,
      color: theme.palette.black.main,
      height: 50,
      width: 50
    },
    actionButton: {
      width: '100%',
      color: theme.palette.white.main
    }
  }
}