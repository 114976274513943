import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ExtraCategory } from '../../../../shared/interfaces/extra-category.interface';
import { ExtraCategoryExtraMenuitem } from '../../../../shared/interfaces/extra-category-extra-menuitem.interface';

const initialState = {
    extraCategories: [] as ExtraCategory[]
}

export const extraCategorySlice = createSlice({
    name: 'extraCategory',
    initialState,
    reducers: {
        reset: () => initialState,
        addExtraCategories: (state, action: PayloadAction<ExtraCategory[]>) => {
            if (state.extraCategories.length > 0) {
                let newCategories = action.payload.filter(c => state.extraCategories.map(i => i.id).indexOf(c.id) === -1);
                if (newCategories && newCategories.length > 0) {
                    state.extraCategories = [...state.extraCategories, ...newCategories];
                }
            } else {
                state.extraCategories = action.payload;
            }
        },
        addExtraCategory: (state, action: PayloadAction<ExtraCategory>) => {
            state.extraCategories.push(action.payload);
        },
        addExtraMenuitem: (state, action: PayloadAction<ExtraCategoryExtraMenuitem>) => {
            const extraCategory = state.extraCategories.find(c => c.id === action.payload.extraCategoryId) as ExtraCategory;
            extraCategory.extraCategoryExtraMenuitems.push(action.payload);
        },
        removeExtraMenuitem: (state, action: PayloadAction<ExtraCategoryExtraMenuitem>) => {
            const extraCategory = state.extraCategories.find(c => c.id === action.payload.extraCategoryId) as ExtraCategory;
            extraCategory.extraCategoryExtraMenuitems = extraCategory.extraCategoryExtraMenuitems.filter(em => em.id !== action.payload.id);
        },
        deleteExtraMenuitem: (state, action: PayloadAction<string>) => {
            state.extraCategories.forEach(extraCategory => {
                extraCategory.extraCategoryExtraMenuitems = extraCategory.extraCategoryExtraMenuitems.filter(em => em.extraMenuitemId !== action.payload)
            })
        },
        updateExtraMenuitem: (state, action: PayloadAction<ExtraCategoryExtraMenuitem>) => {
            if (action.payload.extraCategoryId) {
                const extraCategory = state.extraCategories.find(c => c.id === action.payload.extraCategoryId) as ExtraCategory;
                extraCategory.extraCategoryExtraMenuitems.forEach(em => {
                    if (em.id === action.payload.id) {
                        Object.assign(em, action.payload);
                    }
                })
                extraCategory.extraCategoryExtraMenuitems.sort((a: ExtraCategoryExtraMenuitem, b: ExtraCategoryExtraMenuitem) => a.viewOrder - b.viewOrder);
            } else {
                state.extraCategories.forEach(extraCategory => {
                    extraCategory.extraCategoryExtraMenuitems.forEach(em => {
                        if (em.extraMenuitemId === action.payload.id) {
                            Object.assign(em.extraMenuitem, action.payload);
                        }
                    })
                    extraCategory.extraCategoryExtraMenuitems.sort((a: ExtraCategoryExtraMenuitem, b: ExtraCategoryExtraMenuitem) => a.viewOrder - b.viewOrder);
                })
            }
        },
        updateExtraMenuitems: (state, action: PayloadAction<{ extraCategoryId: string, extraCategoryExtraMenuitems: Partial<ExtraCategoryExtraMenuitem>[] }>) => {
            for (let change of action.payload.extraCategoryExtraMenuitems) {
                const extraCategory = state.extraCategories.find(c => c.id === action.payload.extraCategoryId) as ExtraCategory;
                extraCategory.extraCategoryExtraMenuitems.forEach(em => {
                    if (em.id === change.id) {
                        Object.assign(em, change);
                    }
                })
                extraCategory.extraCategoryExtraMenuitems.sort((a: ExtraCategoryExtraMenuitem, b: ExtraCategoryExtraMenuitem) => a.viewOrder - b.viewOrder);
            }
        },
        updateExtraCategory: (state, action: PayloadAction<Partial<ExtraCategory>>) => {
            const extraCategory = state.extraCategories.find(c => c.id === action.payload.id) as ExtraCategory;
            if (extraCategory) {
                Object.assign(extraCategory, action.payload);
            }
        },
        updateExtraCategories: (state, action: PayloadAction<Partial<ExtraCategory>[]>) => {
            for (let change of action.payload) {
                const extraCategory = state.extraCategories.find(c => c.id === change.id) as ExtraCategory;

                if (extraCategory) {
                    Object.assign(extraCategory, change);
                }
            }
        },
        deleteExtraCategory: (state, action: PayloadAction<string>) => {
            state.extraCategories = state.extraCategories.filter(ec => ec.id !== action.payload);
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    reset,
    addExtraCategories,
    addExtraCategory,
    updateExtraCategory,
    updateExtraCategories,
    deleteExtraCategory,
    addExtraMenuitem,
    removeExtraMenuitem,
    deleteExtraMenuitem,
    updateExtraMenuitem,
    updateExtraMenuitems
} = extraCategorySlice.actions

export default { extraCategoryReducer: extraCategorySlice.reducer }
