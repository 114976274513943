import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Restaurant } from '../../shared/interfaces/restaurant.interface';
import { Performance } from '../../shared/interfaces/performance.interface';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { useEffect, useState } from 'react';
import { newGuid } from '../../core/utilities';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { Artist } from '../../shared/interfaces/artist.interface';
import { Stage } from '../../shared/interfaces/stage.interface';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface AddPerformanceDialogProps {
    open: boolean;
    yesLabel: string;
    noLabel: string;
    festivalId: string;
    id?: string;
    performance: Performance;
    performances: Performance[];
    artists: Artist[];
    stages: Stage[];
    onClose: (e: any) => void;
}

export default function AddPerformanceDialog(props: AddPerformanceDialogProps) {

    const { onClose, open } = props;
    const [id, setId] = useState("");
    const [selectedArtistId, setSelectedArtistId] = useState("");
    const [selectedStageId, setSelectedStageId] = useState("");

    const [startDatetime, setStartDatetime] = useState(dayjs.utc());
    const [endDatetime, setEndDatetime] = useState(dayjs.utc());

    const [selectedArtistIdError, setSelectedArtistIdError] = useState("");
    const [selectedStageIdError, setSelectedStageIdError] = useState("");
    const [startDatetimeError, setStartDatetimeError] = useState("");
    const [endDatetimeError, setEndDatetimeError] = useState("");


    const t = useTranslation();

    useEffect(() => {

        // update data
        setId(props?.performance?.id || "");
        setSelectedArtistId(props?.performance?.artistId || "");
        setSelectedStageId(props?.performance?.stageId || "");
        setStartDatetime(dayjs.utc(props?.performance?.startDatetime) || dayjs.utc());
        setEndDatetime(dayjs.utc(props?.performance?.endDatetime) || dayjs.utc());
    }, [props])

    useEffect(() => {
        validateForm();
    }, [selectedArtistId, selectedStageId, startDatetime, endDatetime])

    const handleOkClose = () => {

        if (validateForm()) {
            onClose({
                id: props?.performance?.id ? id : newGuid(),
                festivalId: props?.festivalId,
                artistId: selectedArtistId,
                artist: props?.artists?.find(b => b.id === selectedArtistId),
                stageId: selectedStageId,
                stage: props?.stages?.find(s => s.id === selectedStageId),
                startDatetime: startDatetime.toISOString(),
                endDatetime: endDatetime.toISOString()
            });
        }
    };

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!selectedArtistId) {
            setSelectedArtistIdError("* Required");
            isValid = false;
        } else {
            setSelectedArtistIdError("");
        }

        if (!selectedStageId) {
            setSelectedStageIdError("* Required");
            isValid = false;
        } else {
            setSelectedStageIdError("");
        }

        // if (!startDatetime) {
        //     setStartDatetimeError("* Required");
        //     isValid = false;
        // } else {
        //     setStartDatetimeError("");
        // }

        // if (!endDatetime) {
        //     setEndDatetimeError("* Required");
        //     isValid = false;
        // } else {
        //     setEndDatetimeError("");
        // }

        return isValid;
    };

    const handleCancelClose = () => {
        onClose(null);
    };

    function handleSelectedArtistChange(event: SelectChangeEvent) {
        setSelectedArtistId(event.target.value as string);
    }

    function handleSelectedStageChange(event: SelectChangeEvent) {
        setSelectedStageId(event.target.value as string);
    }

    function handleStartDatetimeChange(event: any) {
        setStartDatetime(event);
    }

    function handleEndDatetimeChange(event: any) {
        setEndDatetime(event);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props?.performance?.id ? t('dialogs.titles.updatePerformance') : t('dialogs.titles.addPerformance')}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    sx={styles.content}
                >
                    <Grid item xs={12}>
                        <FormControl
                            disabled={props?.id ? true : false}
                            error={!!selectedArtistIdError}
                            size="small"
                            sx={{
                                width: '100%'
                            }}
                        >
                            <InputLabel>{t('dialogs.labels.artist')}</InputLabel>
                            <Select
                                value={selectedArtistId}
                                label={t('dialogs.labels.restaurant')}
                                onChange={handleSelectedArtistChange}
                            >
                                {props?.artists?.map((artist) => (
                                    <MenuItem key={artist.id} value={artist.id}>
                                        <ListItemText primary={artist.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {!!selectedArtistIdError && <Typography
                                sx={{
                                    color: '#d32f2f',
                                    fontWeight: 400,
                                    fontSize: '0.75rem',
                                    lineHeight: 1.66,
                                    textAlign: 'left',
                                    marginTop: '3px',
                                    marginRight: '14px',
                                    marginBottom: 0,
                                    marginLeft: '14px'
                                }}
                            >{selectedArtistIdError}</Typography>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            disabled={props?.id ? true : false}
                            error={!!selectedStageIdError}
                            size="small"
                            sx={{
                                width: '100%'
                            }}
                        >
                            <InputLabel>{t('dialogs.labels.stage')}</InputLabel>
                            <Select
                                value={selectedStageId}
                                label={t('dialogs.labels.stage')}
                                onChange={handleSelectedStageChange}
                            >
                                {props?.stages?.map((stage) => (
                                    <MenuItem key={stage.id} value={stage.id}>
                                        <ListItemText primary={stage.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {!!selectedStageIdError && <Typography
                                sx={{
                                    color: '#d32f2f',
                                    fontWeight: 400,
                                    fontSize: '0.75rem',
                                    lineHeight: 1.66,
                                    textAlign: 'left',
                                    marginTop: '3px',
                                    marginRight: '14px',
                                    marginBottom: 0,
                                    marginLeft: '14px'
                                }}
                            >{selectedStageIdError}</Typography>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimePicker
                            timezone="UTC"
                            ampm={false}
                            value={startDatetime}
                            onChange={handleStartDatetimeChange}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimePicker
                            timezone="UTC"
                            ampm={false}
                            value={endDatetime}
                            onChange={handleEndDatetimeChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="black" variant='contained' size="small" onClick={handleCancelClose}>{props?.noLabel || 'Cancel'}</Button>
                <Button variant='contained' size="small" onClick={handleOkClose}>{props?.yesLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog >
    );
}

const styles = {
    content: {
        // width: '550px',
        height: '250px'
    }
}