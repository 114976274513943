import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    Chip, Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Stack,
    styled,
    Typography,
    useTheme,
    Slide
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { Order } from "../../shared/interfaces/order.interface";
import { useTranslation } from "react-multi-lang";
import { MINIO_URL } from "../../core/api/axios";
import { useSelector } from "react-redux";
import { RootState } from "../../core/store/store";
import { OrderStateEnum } from "../../shared/interfaces/order-state.interface";
import { BoldTypography } from "../../components/StyledComponents/StyledComponents";


export interface OrderDialogProps {
    open: boolean;
    data: Order | null;
    showActions?: boolean;
    onClose: () => void;
    onApproveAndPrepare?: (order: Order | null) => void;
    onDone?: (order: Order | null) => void;
    onCancel?: (order: Order | null) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrderCard = styled(Card)(({ theme }) => ({
    maxWidth: 275,
    borderRadius: "10px",
    boxShadow: '0px 2px 6px rgba(13, 10, 44, 0.08)',
    "& .MuiCardContent-root": {
        padding: "4px"
    }
}));

const OrdersGrid = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", //the width of the card 
    justifyContent: "start",
    gridGap: "10px"
}));


export default function OrderDialog(props: OrderDialogProps) {

    const theme = useTheme();
    const { onClose, onApproveAndPrepare, onDone, open } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();


    const handleClose = () => {
        onClose();
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = (value: boolean) => {
        setOpenConfirm(false);
        if (value) {
            onClose();
            // props.data.isPreparing = false;
        }
    };

    const handleDone = () => {
        props?.onDone?.(props.data);
        onClose();
    }

    const handleCancel = () => {
        setOpenConfirm(true);
        // props?.onCancel?.(props.data);
        // onClose();
    }

    const handleApproveAndPrepare = () => {
        props?.onApproveAndPrepare?.(props.data);
        onClose();
    }

    function orderPrice() {
        let total_price = (props?.data?.orderMenuitems?.map((orderMenuitem: any) => orderMenuitem?.menuitem?.price * orderMenuitem?.amount)?.reduce((a: number, b: number) => a + b, 0) || 0);
        return Math.round((total_price + Number.EPSILON) * 100) / 100;
    }

    function getThumbnailURL(menuitemId: string) {
        return `${MINIO_URL}/menuitems/${menuitemId}-sm`;
    }

    return (
        <>
            {props?.data != null && <>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    TransitionComponent={Transition}
                    onClose={handleClose}
                    open={open}
                    PaperProps={{
                        sx: {
                            height: "80vh",
                            width: "80vw"
                        }
                    }}
                >
                    {/* <DialogTitle>Order Detail</DialogTitle> */}

                    <DialogContent sx={{
                        background: theme.palette.offWhite.main
                    }}>
                        <div style={{
                            borderWidth: "50px",
                            borderStyle: "solid",
                            borderColor: `${theme.palette.offWhite.main} ${theme.palette.offWhite.main} transparent transparent`,
                            position: "absolute",
                            top: "0",
                            right: "0",
                            width: "0",
                            height: "0",
                            filter: "drop-shadow(0 1px 15px gray)"
                        }}
                        >
                            <div style={{
                                position: "relative",
                                top: "-30px",
                                left: "8px",
                                cursor: "pointer"
                            }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <OrdersGrid>
                            {props?.data?.orderMenuitems?.map((orderMenuitem: any, index: number) => (

                                <Grid item xs key={index}>
                                    <OrderCard>
                                        <CardContent>
                                            <Stack spacing={1} sx={{
                                                padding: "24px"
                                            }}>

                                                <Box sx={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "20px"
                                                }}>
                                                    {/* <img
                                                        width={"40px"}
                                                        style={{
                                                            borderRadius: "5px"
                                                        }}
                                                        src={`${"https://source.unsplash.com/100x100/?meal"}?${Math.random()}`} alt="image not found"
                                                    /> */}
                                                    <img
                                                        style={{
                                                            borderRadius: "5px",
                                                            width: "40px",
                                                            height: "40px",
                                                            objectFit: "cover"
                                                        }}
                                                        src={getThumbnailURL(orderMenuitem?.menuitem?.id)}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = require("../../../../src/assets/menuitem_placeholder.png");
                                                        }}
                                                    />
                                                </Box>

                                                <BoldTypography>
                                                    {orderMenuitem?.menuitem?.name}
                                                </BoldTypography>

                                                <Stack direction="row" justifyContent="space-between" spacing={1}>
                                                    <Typography>
                                                        {t('pages.orders.orderDetails.labels.quantity')}
                                                    </Typography>
                                                    <Typography sx={{ color: theme.palette.primary.main }}>
                                                        {orderMenuitem?.amount}x
                                                    </Typography>
                                                </Stack>

                                                <Divider />

                                                {orderMenuitem?.note &&
                                                    <>
                                                        <Stack direction="column" spacing={1}>
                                                            <BoldTypography>
                                                                {t('pages.orders.orderDetails.labels.note')}
                                                            </BoldTypography>

                                                            <Typography>
                                                                {orderMenuitem?.note}
                                                            </Typography>
                                                        </Stack>

                                                        <Divider />
                                                    </>
                                                }

                                                <BoldTypography>
                                                    Variant
                                                </BoldTypography>

                                                <Stack spacing={1}>
                                                    {orderMenuitem?.orderMenuitemExtraMenuitems?.map((orderMenuitemExtraMenuitem: any, index: number) => (
                                                        <Chip
                                                            key={orderMenuitemExtraMenuitem.id}
                                                            label={orderMenuitemExtraMenuitem.extraMenuitem.name}
                                                            sx={{
                                                                background: theme.palette.natural.main,
                                                                color: 'white'
                                                            }}
                                                        ></Chip>
                                                    ))}
                                                </Stack>

                                                <Stack direction="row" justifyContent="space-between" spacing={1}>
                                                    <Typography>
                                                        {t('pages.orders.orderDetails.labels.totalPrice')}
                                                    </Typography>

                                                    <BoldTypography>
                                                        {Math.round((orderMenuitem?.menuitem?.price * orderMenuitem?.amount + Number.EPSILON) * 100) / 100} €
                                                    </BoldTypography>
                                                </Stack>
                                            </Stack>

                                        </CardContent>
                                    </OrderCard>
                                </Grid>
                            ))}
                        </OrdersGrid>
                    </DialogContent>

                    <DialogActions sx={{
                        background: 'white',
                        boxShadow: '0px 2px 6px rgba(13, 10, 44, 0.08)',
                        borderRadius: '10px',
                        justifyContent: "space-between",
                        padding: "24px"
                    }}>
                        <Stack direction="row" spacing={5}>
                            <Stack direction="row" spacing={1}>
                                <BoldTypography sx={{
                                    color: theme.palette.primary.main
                                }}>
                                    {t('pages.orders.orderDetails.labels.table')}
                                </BoldTypography>

                                <Typography sx={{
                                    color: theme.palette.primary.main
                                }}>
                                    {props?.data?.orderNumber}
                                </Typography>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                                <BoldTypography>
                                    {t('pages.orders.orderDetails.labels.orderedAgo')}:
                                </BoldTypography>

                                <Typography>
                                    {new Date(props?.data?.createdAt).toLocaleTimeString()}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <BoldTypography>
                                    {t('pages.orders.orderDetails.labels.paid')}:
                                </BoldTypography>

                                <Typography>
                                    {orderPrice()} €
                                </Typography>
                            </Stack>

                        </Stack>

                        {props?.showActions && <Stack direction="row" spacing={1}>
                            {props?.data?.orderStateId === OrderStateEnum.IsPreparing ? (
                                <>
                                    <Button
                                        color="natural"
                                        variant="outlined"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        {t('pages.orders.orderDetails.actions.closeWindow')}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        size="small"
                                        autoFocus
                                        onClick={handleDone}
                                    >
                                        {t('pages.orders.orderDetails.actions.done')}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        color="black"
                                        variant="contained"
                                        size="small"
                                        onClick={handleCancel}
                                    >
                                        {t('pages.orders.orderDetails.actions.reject')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        autoFocus
                                        onClick={handleApproveAndPrepare}
                                    >
                                        {t('pages.orders.orderDetails.actions.accept')}
                                    </Button>
                                </>
                            )}
                        </Stack>}

                    </DialogActions>

                </Dialog >

                <ConfirmDialog
                    open={openConfirm}
                    onClose={handleConfirmClose}
                    title={t('dialogs.titles.reject')}
                    content={t('dialogs.contents.reject')}
                    noLabel={t('dialogs.actions.keep')}
                    yesLabel={t('dialogs.actions.reject')}
                />
            </>
            }
        </>
    );
}