import React, { FC, useState } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';
import { Accordion, AccordionSummary, List, ListItem, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import RecommendIcon from '@mui/icons-material/Recommend';
import { newGuid, reorder } from '../../core/utilities';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useDispatch } from 'react-redux';
import { Category } from '../../shared/interfaces/category.interface';
import { Menuitem } from '../../shared/interfaces/menuitem.interface';
import { useTranslation } from 'react-multi-lang';
import { setPanelContext } from '../../core/store/features/sidePanel/sidePanelSlice';
import { addCategory, addMenuitem, deleteCategory, updateCategories, updateCategory, updateMenuitem } from '../../core/store/features/category/categorySlice';
import ContextMenu, { MenuData } from '../../components/ContextMenu/ContextMenu';
import DeleteSafeDialog from '../../dialogs/DeleteSafeDialog/DeleteSafeDialog';
import CreateMenuItemButton from '../../components/CreateMenuItemButton/CreateMenuItemButton';
import { RoundedAccordion, RoundedAccordionSummary } from '../../components/StyledComponents/StyledComponents';


export type CategoryListProps = {
    categories: Category[];
};

const CategoryList: FC<CategoryListProps> = React.memo(({
    categories
}) => {

    const theme = useTheme();
    const [openSafeConfirm, setOpenSafeConfirm] = useState(false);
    const [toDeleteItem, setToDeleteItem] = useState<Menuitem | Category | null>(null);
    const [contextMenuData, setContextMenuData] = React.useState<MenuData | null>(null);


    const axiosSecure = useAxiosSecure();
    const dispatch = useDispatch();
    const t = useTranslation();


    async function onDragCategoryEnd(e: DropResult) {
        // dropped outside the list
        const { destination, source, draggableId } = e;

        if (!destination) return;

        if (destination.index !== source.index) {
            const newItems = reorder(categories, source.index, destination.index);

            dispatch(updateCategories(
                newItems?.map((c, index) => ({
                    ...c,
                    viewOrder: index
                }))
            ));

            let response = await axiosSecure.put(`/api/category/many`,
                newItems?.map((c, index) => ({
                    id: c?.id,
                    viewOrder: index
                }))
            );
        }
    };

    async function onDragMenuitemEnd(e: DropResult, category: any) {
        // dropped outside the list
        const { destination, source, draggableId } = e;

        if (!destination) return;

        if (destination.index !== source.index) {
            let newItems: Menuitem[] = reorder(category.menuitems, source.index, destination.index);

            let newCategory = ({ ...category });
            newCategory.menuitems = newItems;

            dispatch(updateCategory(newCategory));

            let response = await axiosSecure.put(`/api/menuitem/many`,
                newItems?.map((c, index) => ({
                    id: c?.id,
                    viewOrder: index
                }))
            );
        }
    };

    function handleCreateNewMenuItemClick(event: any, category: Category) {
        event.stopPropagation();
        dispatch(setPanelContext({
            action: 'CREATE', contextType: 'Menuitem', contextObject: {
                id: newGuid(),
                categoryId: category.id
            }
        }));
    }

    function handleEditMenuitemClick(event: any, menuitem: Menuitem) {
        event.stopPropagation();
        dispatch(setPanelContext({ action: 'UPDATE', contextType: 'Menuitem', contextObject: menuitem }));
    }

    async function handleVisibilityMenuitemClick(event: any, menuitem: Menuitem) {
        event.stopPropagation();

        dispatch(updateMenuitem({ ...menuitem, isHidden: !menuitem.isHidden }));

        let response = await axiosSecure.put(`/api/menuitem`, {
            id: menuitem?.id,
            isHidden: !menuitem.isHidden
        });
    }

    async function handlePopularMenuitemClick(event: any, menuitem: Menuitem) {
        event.stopPropagation();

        dispatch(updateMenuitem({ ...menuitem, isPopular: !menuitem.isPopular }));

        let response = await axiosSecure.put(`/api/menuitem`, {
            id: menuitem?.id,
            isPopular: !menuitem.isPopular
        });
    }

    async function handleRecommendedMenuitemClick(event: any, menuitem: Menuitem) {
        event.stopPropagation();

        dispatch(updateMenuitem({ ...menuitem, isRecommended: !menuitem.isRecommended }));

        let response = await axiosSecure.put(`/api/menuitem`, {
            id: menuitem?.id,
            isRecommended: !menuitem.isRecommended
        });
    }

    function handleEditCategoryClick(event: any, category: Category) {
        event.stopPropagation();
        dispatch(setPanelContext({ action: 'UPDATE', contextType: 'Category', contextObject: category }));
    }

    async function handleDuplicateClick(event: any, data: Category | Menuitem, dataType: 'Category' | 'Menuitem') {
        event.stopPropagation();

        if (dataType === 'Category') {

            let dataChange = { ...data, id: newGuid() };
            let response: any = await axiosSecure.post(`/api/category`, dataChange, false);
            if (response?.data && response?.data?.length > 0) {
                dispatch(addCategory(response?.data[0]));
            }
        }

        if (dataType === 'Menuitem') {
            let dataChange = { ...data, isHidden: true, id: newGuid() };
            let response: any = await axiosSecure.post(`/api/menuitem`, dataChange, false);
            if (response?.data && response?.data?.length > 0) {
                dispatch(addMenuitem(response?.data[0]));
            }
        }

    }

    function handleOpenSafeConfirm(event: any, item: Menuitem | Category | null) {
        event.stopPropagation();
        setToDeleteItem(item);
        setOpenSafeConfirm(true);
    }

    async function handleSafeConfirmClose(event: any) {
        if (event) {

            if (toDeleteItem) {
                // delete menuitem
                if ("categoryId" in toDeleteItem) {
                    axiosSecure.delete(`/api/menuitem/${toDeleteItem?.id}`, true);
                    let category = ({ ...categories.find(c => c.id === toDeleteItem.categoryId) }) as Category;
                    if (category) {
                        category.menuitems = category.menuitems.filter(m => m.id !== toDeleteItem.id)
                        dispatch(updateCategory(category));
                    }
                }
                // delete category
                else {
                    axiosSecure.delete(`/api/category/${toDeleteItem?.id}`, true);
                    dispatch(deleteCategory(toDeleteItem?.id))
                }
            }
        }

        setToDeleteItem(null);
        setOpenSafeConfirm(false);
    }

    const handleContextMenu = (event: React.MouseEvent, data: Menuitem | Category, dataType: 'Menuitem' | 'Category') => {
        event.preventDefault();
        setContextMenuData(
            contextMenuData === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    data,
                    dataType
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleContextMenuClose = () => {
        setContextMenuData(null);
    };

    function handleDeleteActionClick(event: any, item: any, data: any) {
        handleOpenSafeConfirm(event, data)
    }

    async function handleDuplicateActionClick(event: any, item: any, data: any, dataType: any) {

        let dataChange = { ...data, id: newGuid() };

        // TODO: copy menuitems
        if (dataType === 'Category') {

            let response: any = await axiosSecure.post(`/api/category`, dataChange, false);
            if (response?.data && response?.data?.length > 0) {
                dispatch(addCategory(response?.data[0]));
            }
        }

        if (dataType === 'Menuitem') {
            dataChange.isHidden = true;
            let response: any = await axiosSecure.post(`/api/menuitem`, dataChange, false);
            if (response?.data && response?.data?.length > 0) {
                dispatch(addMenuitem(response?.data[0]));
            }
        }
    }

    return (
        <>
            <ContextMenu
                data={contextMenuData}
                items={[
                    {
                        icon: <FilterNoneIcon fontSize="small" />,
                        label: t('containers.categoryList.actions.duplicate'),
                        type: 'actionItem',
                        onAction: handleDuplicateActionClick
                    },
                    {
                        type: 'divider'
                    },
                    {
                        icon: <DeleteOutlineIcon fontSize="small" />,
                        label: t('containers.categoryList.actions.delete'),
                        type: 'actionItem',
                        onAction: handleDeleteActionClick
                    }
                ]}
                open={contextMenuData !== null}
                onClose={handleContextMenuClose}
            />

            <DeleteSafeDialog
                open={openSafeConfirm}
                onClose={handleSafeConfirmClose}
                title={t('containers.categoryList.dialog.title')}
                content={t('containers.categoryList.dialog.content')}
                confirmPhrase={toDeleteItem?.name || ''}
                noLabel={t('containers.categoryList.actions.keep')}
                yesLabel={t('containers.categoryList.actions.delete')}
            />

            <DragDropContext onDragEnd={onDragCategoryEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}
                            sx={styles.droppableList}
                        >
                            {categories.map((category, index) => (
                                <Draggable draggableId={category.id} index={index} key={category.id}>
                                    {(provided, snapshot) => (
                                        <RoundedAccordion
                                            disabled={category?._isDraft}
                                            disableGutters
                                            elevation={0}
                                            sx={{
                                                boxShadow: snapshot.isDragging ? "0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);" : "initial"
                                            }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <RoundedAccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                onContextMenu={(e: any) => handleContextMenu(e, category, 'Category')}
                                            >
                                                <Stack
                                                    spacing={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={styles.categoryItem}
                                                >
                                                    <div
                                                        style={{
                                                            visibility: !category?.currentForDay ? 'visible' : 'hidden'
                                                        }}
                                                        {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon />
                                                    </div>

                                                    <Typography>
                                                        <strong>{category.name}</strong>
                                                    </Typography>

                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                ...styles.icon,
                                                                visibility: !category?.currentForDay ? 'visible' : 'hidden'
                                                            }}
                                                            fontSize="small"
                                                            onClick={(e) => handleEditCategoryClick(e, category)}
                                                        />

                                                        <FilterNoneIcon
                                                            fontSize="small"
                                                            onClick={(e) => handleDuplicateClick(e, category, 'Category')}
                                                        />
                                                    </Stack>
                                                </Stack>

                                            </RoundedAccordionSummary>

                                            <Stack
                                                direction="row"
                                                justifyContent="space-around"
                                                sx={{
                                                    mt: 2
                                                }}
                                            >
                                                <CreateMenuItemButton
                                                    onClick={(e) => handleCreateNewMenuItemClick(e, category)}
                                                />
                                            </Stack>

                                            <DragDropContext onDragEnd={(event) => onDragMenuitemEnd(event, category)}>
                                                <Droppable droppableId={"menu-items-list" + category.id}>
                                                    {(provided2) => (
                                                        <List ref={provided2.innerRef} {...provided2.droppableProps}>
                                                            {category?.menuitems?.map((menuitem: any, index2: number) => (
                                                                <Draggable draggableId={menuitem.id} index={index2} key={menuitem.id}>
                                                                    {(provided2, snapshot2) => (
                                                                        <ListItem
                                                                            sx={{
                                                                                height: '80px',
                                                                                color: menuitem?.isHidden ? '#8E8E93' : 'black'
                                                                            }}
                                                                            ref={provided2.innerRef}
                                                                            {...provided2.draggableProps}
                                                                            onContextMenu={(e: any) => handleContextMenu(e, menuitem, 'Menuitem')}
                                                                        >
                                                                            <Stack
                                                                                spacing={2}
                                                                                direction="row"
                                                                                justifyContent="space-between"
                                                                                sx={{
                                                                                    ...styles.menuitem,
                                                                                    boxShadow: snapshot2.isDragging ? "0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);" : "initial"
                                                                                }}
                                                                            >
                                                                                <Stack
                                                                                    direction="row"
                                                                                    alignItems="center"
                                                                                    spacing={2}
                                                                                    sx={styles.overflow}
                                                                                >
                                                                                    <div
                                                                                        {...provided2.dragHandleProps}
                                                                                    >
                                                                                        <DragIndicatorIcon fontSize="small" />
                                                                                    </div>
                                                                                    <Typography fontSize="small">
                                                                                        <strong>{menuitem.name}</strong>
                                                                                    </Typography>
                                                                                    <Typography fontSize="small">
                                                                                        <strong>{menuitem.weight}</strong>
                                                                                    </Typography>
                                                                                </Stack>

                                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                                    <Typography fontSize="small">
                                                                                        <strong>{menuitem.price}€</strong>
                                                                                    </Typography>
                                                                                    <EditIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleEditMenuitemClick(e, menuitem)}
                                                                                    />

                                                                                    <FilterNoneIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleDuplicateClick(e, menuitem, 'Menuitem')}
                                                                                    />

                                                                                    <DeleteOutlineIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleOpenSafeConfirm(e, menuitem)}
                                                                                    />

                                                                                    {/* {menuitem?.isRecommended ? (<RecommendIcon
                                                                                        sx={{
                                                                                            ...styles.icon,
                                                                                            color: theme.palette.warning.main
                                                                                        }}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleRecommendedMenuitemClick(e, menuitem)}
                                                                                    />) : (<RecommendIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleRecommendedMenuitemClick(e, menuitem)}
                                                                                    />)}

                                                                                    {menuitem?.isPopular ? (<WhatshotIcon
                                                                                        sx={{
                                                                                            ...styles.icon,
                                                                                            color: theme.palette.primary.main
                                                                                        }}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handlePopularMenuitemClick(e, menuitem)}
                                                                                    />) : (<WhatshotIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handlePopularMenuitemClick(e, menuitem)}
                                                                                    />)} */}

                                                                                    {menuitem?.isHidden ? (<VisibilityOffOutlinedIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleVisibilityMenuitemClick(e, menuitem)}
                                                                                    />) : (<VisibilityOutlinedIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleVisibilityMenuitemClick(e, menuitem)}
                                                                                    />)}

                                                                                </Stack>
                                                                            </Stack>
                                                                        </ListItem>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided2.placeholder}
                                                        </List>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>

                                        </RoundedAccordion>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext >

        </>
    );
});

export default CategoryList;


const styles = {
    droppableList: {
        paddingTop: '0px'
    },
    categoryItem: {
        width: '100%',
        height: '20px'
    },
    icon: {
        cursor: 'pointer'
    },
    menuitem: {
        width: '100%',
        height: '50px',
        borderRadius: '0.6rem',
        bgcolor: 'white'
    },
    overflow: {
        overflow: 'hidden'
    }
}