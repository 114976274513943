import * as React from 'react';
import { useEffect, useState } from 'react';
import useAxiosSecure from '../../hooks/useAxiosSecure';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { isBoolean } from '../../app/core/utilities';
import { Box, Checkbox, Stack, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/core/store/store';
import { Order } from '../../app/shared/interfaces/order.interface';
import { useTranslation } from 'react-multi-lang';
import { OrderStateEnum } from '../../app/shared/interfaces/order-state.interface';
import { addOrders, selectOrdersForCurrentRestaurant } from '../../app/core/store/features/order/orderSlice';
import OrderDialog from '../../app/dialogs/OrderDialog/OrderDialog';


interface Column {
    field: string;
    headerName: string;
    minWidth?: number;
    width?: number;
    align?: 'right';
    format?: (value: number) => string;
}


export default function History() {

    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [selectedRow, setSelectedRow] = useState<Order | null>(null);
    const t = useTranslation();


    const columns: readonly Column[] = [
        { field: 'state', headerName: t('pages.history.labels.state'), width: 400 },
        { field: 'orderNumber', headerName: t('pages.history.labels.table'), width: 150 },
        { field: 'note', headerName: t('pages.history.labels.note'), minWidth: 170 }
    ];

    const handleRowClick = (rowData: Order) => {
        setSelectedRow(rowData);
    }

    const handleDetailClose = () => {
        setSelectedRow(null);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);

    const orders = useSelector(selectOrdersForCurrentRestaurant);

    const dispatch = useDispatch();


    useEffect(() => {
        async function apiCall() {
            let response = await axiosSecure.get(`/api/order?restaurantId=${auth?.user?.restaurantId}`, true);

            dispatch(addOrders(response.data?.map((order: Order) => ({
                ...order,
                state: order?.orderStateId === OrderStateEnum.IsDone ? t('orderState.isDone') : (
                    order?.orderStateId === OrderStateEnum.IsPreparing ? t('orderState.isPreparing') : (order?.orderStateId === OrderStateEnum.IsCanceled ? t('orderState.isCanceled') : t('orderState.isCreated'))
                )
            }))));
        }

        apiCall();
    }, [])

    return (
        <>
            <Box>
                <TableContainer sx={{
                    flexGrow: 1,
                    minHeight: "100%",
                    overflow: 'scroll'
                }}>
                    <Table stickyHeader aria-label="sticky table" sx={{
                        height: "100%",
                        flexGrow: 1
                    }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.field}
                                        align={column.align}
                                        style={{ width: column.width }}
                                    >
                                        {column.headerName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{
                            flex: 1,
                            minHeight: 0,
                            overflow: "scroll"
                        }}>
                            {orders
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{
                                                bgcolor: 'white'
                                            }}
                                            onClick={() => handleRowClick(row)}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.field];

                                                return (
                                                    <TableCell key={column.field} align={column.align}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {column.field === 'state' &&
                                                                <Box
                                                                    sx={{
                                                                        bgcolor: row?.orderStateId === OrderStateEnum.IsDone ? 'green' : (
                                                                            row?.orderStateId === OrderStateEnum.IsPreparing ? theme.palette.warning.main : (row?.orderStateId === OrderStateEnum.IsCanceled ? 'red' : theme.palette.natural.main)
                                                                        ),
                                                                        width: 10,
                                                                        height: 10,
                                                                        borderRadius: '50%'
                                                                    }}
                                                                />
                                                            }
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: column.field === 'tableNumber' ? 'bold' : 'unset'
                                                                }}
                                                            >{value?.toString()}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    labelRowsPerPage={t('pages.history.labels.rowsPerPage')}
                    component="div"
                    count={orders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>


            <OrderDialog
                open={selectedRow != null}
                onClose={handleDetailClose}
                data={selectedRow}
            />
        </>
    );
}