import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import useAxiosSecure from "../../hooks/useAxiosSecure";


export default function ResetPassword() {

    const t = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const axiosSecure = useAxiosSecure();


    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordNotSameError, setPasswordNotSameError] = useState("");

    const token = searchParams.get("token");

    useEffect(() => {
        validateForm();
    }, [newPassword, confirmPassword])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!newPassword) {
            setNewPasswordError("* Required");
            isValid = false;
        } else {
            setNewPasswordError("");
        }

        if (!confirmPassword) {
            setConfirmPasswordError("* Required");
            isValid = false;
        } else {
            setConfirmPasswordError("");
        }

        if (newPassword != confirmPassword) {
            setPasswordNotSameError("Passwords does not match");
            isValid = false;
        } else {
            setPasswordNotSameError("");
        }

        return isValid;
    };

    function handleNewPasswordChange(event: any) {
        setNewPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event: any) {
        setConfirmPassword(event.target.value);
    }

    async function handleReset(event: any) {
        validateForm();

        let response = await axiosSecure.post(`/api/auth/reset_password`, {
            secretToken: token,
            newPassword,
            confirmPassword
        }, false);

        navigate("/login");
    }


    return (
        <>
            <Stack
                spacing={2}
                alignItems="center"
                sx={styles.container}
            >

                <Box>
                    <Stack spacing={2} alignItems="centerp">
                        <Typography><strong>{t('pages.resetPassword.labels.newPassword')}</strong></Typography>
                        <TextField
                            variant="outlined"
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            error={!!newPasswordError}
                            helperText={newPasswordError}
                        />

                        <Typography><strong>{t('pages.resetPassword.labels.confirmPassword')}</strong></Typography>
                        <TextField
                            variant="outlined"
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            error={!!confirmPasswordError}
                            helperText={confirmPasswordError}
                        />

                        {passwordNotSameError && <Typography><strong>{passwordNotSameError}</strong></Typography>}

                        <Button variant="contained" onClick={handleReset}>
                            {t('pages.resetPassword.actions.reset')}
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>

    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bold: {
        fontSize: 'small',
        fontWeight: 'bold'
    }
}