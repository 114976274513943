import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Order } from '../../shared/interfaces/order.interface';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MobileStepper, Radio, RadioGroup, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Menuitem } from '../../shared/interfaces/menuitem.interface';

import styles from './reject-order-dialog.module.css';
import { useTranslation } from 'react-multi-lang';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export enum RejectReason {
    MenuitemOutOfStock = "1",
    OverloadedService = "2",
    TechnicalReason = "3",
    Other = "4"
}

export interface RejectOrderDialogResult {
    rejectOrder: boolean;
    rejectReason?: RejectReason;
    outOfStockMenuitemIds?: string[];
}

export interface RejectOrderDialogProps {
    open: boolean;
    noLabel: string;
    yesLabel: string;
    title: string;
    content: string;
    order: Order;
    onClose: (value: RejectOrderDialogResult) => void;
}

export default function RejectOrderDialog(props: RejectOrderDialogProps) {

    const { onClose, open } = props;

    const [rejectReason, setRejectReason] = useState<string>(RejectReason.MenuitemOutOfStock);
    const [activeStep, setActiveStep] = useState(0);
    const t = useTranslation();


    let _menuitems: any = {};

    props.order.orderMenuitems.map(om => {
        _menuitems[om.menuitem.id] = false;
    })

    const [missingMenuitems, setMissingMenuitems] = useState<{
        [k: number]: boolean;
    }>(_menuitems);

    // useEffect(() => {
    //     console.log("RejectOrderDialog")
    //     setActiveStep(0);
    // }, [])

    const handleMissingMenuitemChange = (event: React.ChangeEvent<HTMLInputElement>, menuitem: Menuitem) => {
        setMissingMenuitems({
            ...missingMenuitems,
            [menuitem.id]: event.target.checked,
        });
    };

    const handleRejectReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRejectReason((event.target as HTMLInputElement).value);
    };

    const steps = [
        {
            title: t('dialogs.titles.rejectOrderReasonStep'),
            content: (
                <>
                    <FormControl>
                        <RadioGroup
                            value={rejectReason}
                            onChange={handleRejectReasonChange}
                        >
                            <Grid
                                container
                                rowSpacing={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={6}>
                                    <FormControlLabel value={RejectReason.MenuitemOutOfStock} control={<Radio />} label={t('dialogs.labels.menuitemOutOfStock')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel value={RejectReason.OverloadedService} control={<Radio />} label={t('dialogs.labels.overloadedService')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel value={RejectReason.TechnicalReason} control={<Radio />} label={t('dialogs.labels.technicalReason')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel value={RejectReason.Other} control={<Radio />} label={t('dialogs.labels.other')} />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        },
        {
            title: t('dialogs.titles.rejectOrderSoldOutItems'),
            content: (
                <>
                    <FormGroup>
                        {props?.order?.orderMenuitems?.map((orderMenuitem: any, index: number) => (
                            <FormControlLabel
                                key={orderMenuitem.id}
                                control={<Checkbox onChange={(e) => handleMissingMenuitemChange(e, orderMenuitem?.menuitem)} checked={missingMenuitems[orderMenuitem?.menuitem?.id]} />}
                                label={orderMenuitem?.menuitem?.name}
                            />
                        ))}
                    </FormGroup>
                </>
            )
        },
        {
            title: t('dialogs.titles.rejectOrderDone'),
            content: (
                <>
                    <Typography>{t('dialogs.contents.rejectOrderMessagePart1')}</Typography>
                    <br />
                    <Typography><strong>{t('dialogs.contents.rejectOrderMessagePart2')}</strong> {t('dialogs.contents.rejectOrderMessagePart3')}</Typography>
                </>
            )
        },
    ];

    const maxSteps = steps.length;

    const handleYesClose = () => {

        let _rejectReason: RejectReason;
        switch (rejectReason) {
            case "1":
                _rejectReason = RejectReason.MenuitemOutOfStock;
                break;
            case "2":
                _rejectReason = RejectReason.OverloadedService;
                break;
            case "3":
                _rejectReason = RejectReason.TechnicalReason;
                break;
            case "4":
                _rejectReason = RejectReason.Other;
                break;
            default:
                _rejectReason = RejectReason.Other;
                break;
        }

        var checkedMissingMenuitemIds = Object.entries(missingMenuitems)
            .filter(([k, v]) => v)?.map(([k, v]) => k);

        onClose({
            rejectOrder: true,
            rejectReason: _rejectReason,
            outOfStockMenuitemIds: checkedMissingMenuitemIds
        });
    };

    const handleNoClose = () => {
        onClose({
            rejectOrder: false
        });
    };

    const handleNext = () => {
        if (activeStep === 0 && rejectReason !== "1") {
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 2 && rejectReason !== "1") {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle><strong>{steps[activeStep].title}</strong></DialogTitle>
            <DialogContent sx={makeStyles.content}>

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3}>
                        {steps[activeStep].content}
                    </Grid>
                </Grid>

            </DialogContent>

            <MobileStepper
                // variant="progress"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                classes={{
                    dots: styles.hiddenDots, // this will overrides default styles related to dot elements
                }}
                nextButton={
                    <>
                        {activeStep !== maxSteps - 1 && <Button variant="contained" size="small" onClick={handleNext}>{props?.yesLabel || 'Yes'}</Button>}
                        {activeStep === maxSteps - 1 && <Button variant="contained" size="small" onClick={handleYesClose}>{props?.yesLabel || 'Yes'}</Button>}
                    </>
                }
                backButton={
                    <>
                        {activeStep !== 0 && <Button color="black" variant="contained" size="small" onClick={handleBack}>{t('dialogs.actions.back')}</Button>}
                        {activeStep === 0 && <Button color="black" variant="contained" size="small" onClick={handleNoClose}>{props?.noLabel || 'No'}</Button>}
                    </>
                }
            />
        </Dialog>
    );
}

const makeStyles = {
    content: {
        height: 150,
        width: 500
    }
}