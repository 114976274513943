import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Restaurant } from '../../shared/interfaces/restaurant.interface';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { useEffect, useState } from 'react';
import { newGuid } from '../../core/utilities';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { Artist } from '../../shared/interfaces/artist.interface';
import { Stage } from '../../shared/interfaces/stage.interface';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface AddStageDialogProps {
    open: boolean;
    yesLabel: string;
    noLabel: string;
    festivalId: string;
    stage: Stage;
    stages: Stage[];
    onClose: (e: any) => void;
}

export default function AddStageDialog(props: AddStageDialogProps) {

    const { onClose, open } = props;
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [nameError, setNameError] = useState("");

    const t = useTranslation();

    useEffect(() => {
        // update data
        setId(props?.stage?.id || "");
        setName(props?.stage?.name || "");
        setDescription(props?.stage?.description || "");
    }, [props])

    useEffect(() => {
        validateForm();
    }, [name, description])

    const handleOkClose = () => {
        if (validateForm()) {
            onClose({
                id: props?.stage?.id ? id : newGuid(),
                name,
                description,
                festivalId: props?.festivalId
            });
        }
    };

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        return isValid;
    };

    const handleCancelClose = () => {
        onClose(null);
    };

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props?.stage?.id ? t('dialogs.titles.updateStage') : t('dialogs.titles.addStage')}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    sx={styles.content}
                >
                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.name')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            error={!!nameError}
                            helperText={nameError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.description')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            multiline
                            maxRows={4}
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="black" variant='contained' size="small" onClick={handleCancelClose}>{props?.noLabel || 'Cancel'}</Button>
                <Button variant='contained' size="small" onClick={handleOkClose}>{props?.yesLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog >
    );
}

const styles = {
    content: {
        // width: '400px',
        height: '250px'
    }
}