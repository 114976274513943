import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Restaurant } from '../../shared/interfaces/restaurant.interface';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { useEffect, useState } from 'react';
import { newGuid } from '../../core/utilities';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { Artist } from '../../shared/interfaces/artist.interface';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface AddArtistDialogProps {
    open: boolean;
    yesLabel: string;
    noLabel: string;
    festivalId: string;
    artist: Artist;
    artists: Artist[];
    onClose: (e: any) => void;
}

export default function AddArtistDialog(props: AddArtistDialogProps) {

    const { onClose, open } = props;
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [genre, setGenre] = useState("");
    const [description, setDescription] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");

    const [nameError, setNameError] = useState("");
    const [genreError, setGenreError] = useState("");

    const t = useTranslation();

    useEffect(() => {
        // update data
        setId(props?.artist?.id || "");
        setName(props?.artist?.name || "");
        setGenre(props?.artist?.genre || "");
        setDescription(props?.artist?.description || "");
        setPreviewUrl(props?.artist?.previewUrl || "");
    }, [props])

    useEffect(() => {
        validateForm();
    }, [name, genre, description, previewUrl])

    const handleOkClose = () => {
        if (validateForm()) {
            onClose({
                id: props?.artist?.id ? id : newGuid(),
                name,
                description,
                genre,
                previewUrl
            });
        }
    };

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (!genre) {
            setGenreError("* Required");
            isValid = false;
        } else {
            setGenreError("");
        }

        return isValid;
    };

    const handleCancelClose = () => {
        onClose(null);
    };

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleGenreChange(event: any) {
        setGenre(event.target.value);
    }

    function handlePreviewUrlChange(event: any) {
        setPreviewUrl(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props?.artist?.id ? t('dialogs.titles.updateArtist') : t('dialogs.titles.addArtist')}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                    sx={styles.content}
                >
                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.name')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            error={!!nameError}
                            helperText={nameError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.genre')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            value={genre}
                            onChange={handleGenreChange}
                            error={!!genreError}
                            helperText={genreError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.previewUrl')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            value={previewUrl}
                            onChange={handlePreviewUrlChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.description')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            multiline
                            maxRows={4}
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="black" variant='contained' size="small" onClick={handleCancelClose}>{props?.noLabel || 'Cancel'}</Button>
                <Button variant='contained' size="small" onClick={handleOkClose}>{props?.yesLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog >
    );
}

const styles = {
    content: {
        width: '400px',
        height: '600px'
    }
}