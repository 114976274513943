import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, Divider, Grid, Stack, Tab, TextField, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploading from 'react-files-uploading';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { useTranslation } from 'react-multi-lang';
import { MINIO_URL } from '../../core/api/axios';
import { Festival } from '../../shared/interfaces/festival.interface';
import ConfirmDialog from '../../dialogs/ConfirmDialog/ConfirmDialog';
import AddRestaurantDialog from '../../dialogs/AddRestaurantDialog/AddRestaurantDialog';
import FestivalRestaurantItem from '../../components/FestivalRestaurantItem/FestivalRestaurantItem';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StageItem from '../../components/StageItem/StageItem';
import AddArtistDialog from '../../dialogs/AddArtistDialog/AddArtistDialog';
import AddStageDialog from '../../dialogs/AddStageDialog/AddStageDialog';
import { Artist } from '../../shared/interfaces/artist.interface';
import { Performance } from '../../shared/interfaces/performance.interface';
import { Stage } from '../../shared/interfaces/stage.interface';
import AddPerformanceDialog from '../../dialogs/AddPerformanceDialog/AddPerformanceDialog';
import PerformanceItem from '../../components/PerformanceItem/PerformanceItem';
import { dayjs } from '../../core/utilities';
import countries from '../../../assets/countries.json';


export interface FestivalEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null;
    data: Festival;
    showModules: boolean;
    onChangeData: (data: any) => void;
    onDelete: (e: Festival) => void;
}

export default function FestivalEditor(props: FestivalEditorProps) {

    const theme = useTheme();

    const [name, setName] = useState(props?.data?.name || "");
    const [description, setDescription] = useState(props?.data?.description || "");
    const [siteUrl, setSiteUrl] = useState(props?.data?.siteUrl || "");
    const [startDatetime, setStartDatetime] = useState(dayjs(props?.data?.startDatetime) || dayjs());
    const [endDatetime, setEndDatetime] = useState(dayjs(props?.data?.endDatetime) || dayjs());
    const [street, setStreet] = useState(props?.data?.street || "");
    const [zipCode, setZipCode] = useState(props?.data?.zipCode || "");
    const [city, setCity] = useState(props?.data?.city || "");
    const [state, setState] = useState<any>(countries[0] || null);
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [thumbnail, setThumbnail] = useState<string>("../../../../src/assets/festival_placeholder.png");
    const [festivalRestaurants, setFestivalRestaurants] = useState<any[]>([]);
    const [openAddRestaurant, setOpenAddRestaurant] = useState(false);
    const [openDeleteFestivalRestaurantConfirm, setOpenDeleteFestivalRestaurantConfirm] = useState(false);
    const [toDeleteItem, setToDeleteItem] = useState<any>(null);
    const [editFestivalRestaurant, setEditFestivalRestaurant] = useState<any>(null);
    const [isOrderingEnabled, setIsOrderingEnabled] = useState(props?.data?.isOrderingEnabled || false);
    const [isMerchEnabled, setIsMerchEnabled] = useState(props?.data?.isMerchEnabled || false);
    const [isMapEnabled, setIsMapEnabled] = useState(props?.data?.isMapEnabled || false);
    const [isScheduleEnabled, setIsScheduleEnabled] = useState(props?.data?.isScheduleEnabled || false);


    const [nameError, setNameError] = useState("");
    const [siteUrlError, setSiteUrlError] = useState("");
    const [streetError, setStreetError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");
    const [startDatetimeError, setStartDatetimeError] = useState("");
    const [endDatetimeError, setEndDatetimeError] = useState("");

    // OWNER PROFILE
    const [ownerLogin, setOwnerLogin] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerFirstName, setOwnerFirstName] = useState("");
    const [ownerLastName, setOwnerLastName] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [ownerStreet, setOwnerStreet] = useState("");
    const [ownerZipCode, setOwnerZipCode] = useState("");
    const [ownerCity, setOwnerCity] = useState("");
    const [ownerState, setOwnerState] = useState<any>(countries[0] || null);

    const [ownerLoginError, setOwnerLoginError] = useState("");
    const [ownerEmailError, setOwnerEmailError] = useState("");
    const [ownerFirstNameError, setOwnerFirstNameError] = useState("");
    const [ownerLastNameError, setOwnerLastNameError] = useState("");
    const [ownerPhoneError, setOwnerPhoneError] = useState("");
    const [ownerStreetError, setOwnerStreetError] = useState("");
    const [ownerZipCodeError, setOwnerZipCodeError] = useState("");
    const [ownerCityError, setOwnerCityError] = useState("");
    const [ownerStateError, setOwnerStateError] = useState("");

    const [selectedTab, setSelectedTab] = useState('1');

    // PROGRAM
    const [artists, setArtists] = useState([]);
    const [festivalStages, setFestivalStages] = useState<Stage[]>([]);
    const [festivalArtists, setFestivalArtists] = useState<Artist[]>([]);
    const [festivalPerformances, setFestivalPerformances] = useState<Performance[]>([]);

    const [openAddArtist, setOpenAddArtist] = useState(false);
    const [openDeleteArtistConfirm, setOpenDeleteArtistConfirm] = useState(false);
    const [toDeleteArtistItem, setToDeleteArtistItem] = useState<any>(null);
    const [editArtist, setEditArtist] = useState<any>(null);

    const [openAddStage, setOpenAddStage] = useState(false);
    const [openDeleteStageConfirm, setOpenDeleteStageConfirm] = useState(false);
    const [toDeleteStageItem, setToDeleteStageItem] = useState<any>(null);
    const [editStage, setEditStage] = useState<any>(null);


    const [openAddPerformance, setOpenAddPerformance] = useState(false);
    const [openDeletePerformanceConfirm, setOpenDeletePerformanceConfirm] = useState(false);
    const [toDeletePerformanceItem, setToDeletePerformanceItem] = useState<any>(null);
    const [editPerformance, setEditPerformance] = useState<any>(null);


    const auth = useSelector((state: RootState) => state.auth);
    const restaurants = useSelector((state: RootState) => [...state.restaurant.restaurants]);
    const t = useTranslation();
    const axiosSecure = useAxiosSecure();


    useEffect(() => {
        async function apiCall() {

            let response = await axiosSecure.get(`/api/festival/${props?.data?.id}/program`, false);
            setFestivalStages(response.data.stages);
            setFestivalArtists(response.data.artists);

            setFestivalPerformances(response.data.performances);

            let artist_response = await axiosSecure.get(`/api/artist`, false);
            setArtists(artist_response.data);
        }

        apiCall();
    }, [props?.data?.id])


    useEffect(() => {

        setName(props?.data?.name || "");
        setDescription(props?.data?.description || "");
        setSiteUrl(props?.data?.siteUrl || "");
        setStreet(props?.data?.street || "");
        setZipCode(props?.data?.zipCode || "");
        setCity(props?.data?.city || "");

        let country = countries.find(c => c.code === props?.data?.state)
        setState(country || null);

        setStartDatetime(dayjs(props?.data?.startDatetime) || dayjs());
        setEndDatetime(dayjs(props?.data?.endDatetime) || dayjs());

        setIsOrderingEnabled(props?.data?.isOrderingEnabled || false);
        setIsMerchEnabled(props?.data?.isMerchEnabled || false);
        setIsMapEnabled(props?.data?.isMapEnabled || false);
        setIsScheduleEnabled(props?.data?.isScheduleEnabled || false);


        setFileToUpload(null);
        setThumbnail(`${MINIO_URL}/festivals/${props?.data?.id}`);

        setFestivalRestaurants(props?.data?.festivalRestaurants || []);

    }, [props?.data])


    useEffect(() => {

        props?.onChangeData({
            isValid: validateForm(),
            festival: {
                id: props?.data?.id,
                name,
                description,
                siteUrl,
                street,
                zipCode,
                city,
                state: state?.code,
                isOrderingEnabled,
                isMerchEnabled,
                isMapEnabled,
                isScheduleEnabled,
                startDatetime: startDatetime.toISOString(),
                endDatetime: endDatetime.toISOString(),
                image: fileToUpload ? fileToUpload : null,
                festivalRestaurants,
                festivalStages,
                festivalPerformances,
                festivalArtists
            },
            user: {
                id: props?.data?.id,
                login: ownerLogin,
                email: ownerEmail,
                firstName: ownerFirstName,
                lastName: ownerLastName,
                phone: ownerPhone,
                street: ownerStreet,
                zipCode: ownerZipCode,
                city: ownerCity,
                state: ownerState?.code
            }
        });

    }, [name, description, siteUrl, street, zipCode,
        city, state, fileToUpload, festivalRestaurants, startDatetime,
        isOrderingEnabled, isMerchEnabled, isMapEnabled, isScheduleEnabled,
        festivalStages, festivalPerformances, festivalArtists,
        endDatetime, ownerLogin, ownerEmail, ownerFirstName, ownerLastName,
        ownerPhone, ownerStreet, ownerZipCode, ownerCity, ownerState
    ])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (props?.action === 'CREATE') {
            if (!ownerLogin) {
                setOwnerLoginError("* Required");
                isValid = false;
            } else {
                setOwnerLoginError("");
            }

            if (!ownerFirstName) {
                setOwnerFirstNameError("* Required");
                isValid = false;
            } else {
                setOwnerFirstNameError("");
            }

            if (!ownerLastName) {
                setOwnerLastNameError("* Required");
                isValid = false;
            } else {
                setOwnerLastNameError("");
            }

            if (!ownerEmail || !isValidEmail(ownerEmail)) {
                setOwnerEmailError("Invalid email address");
                isValid = false;
            } else {
                setOwnerEmailError("");
            }

            if (!ownerPhone) {
                setOwnerPhoneError("* Required");
                isValid = false;
            } else {
                setOwnerPhoneError("");
            }

            if (!ownerStreet) {
                setOwnerStreetError("* Required");
                isValid = false;
            } else {
                setOwnerStreetError("");
            }

            if (!ownerZipCode) {
                setOwnerZipCodeError("* Required");
                isValid = false;
            } else {
                setOwnerZipCodeError("");
            }

            if (!ownerCity) {
                setOwnerCityError("* Required");
                isValid = false;
            } else {
                setOwnerCityError("");
            }

            if (!ownerState) {
                setOwnerStateError("* Required");
                isValid = false;
            } else {
                setOwnerStateError("");
            }
        }

        if (!siteUrl) {
            setSiteUrlError("* Required");
            isValid = false;
        } else {
            setSiteUrlError("");
        }

        // if (!startDatetime) {
        //     setStartDatetimeError("* Required");
        //     isValid = false;
        // } else {
        //     setStartDatetimeError("");
        // }

        // if (!endDatetime) {
        //     setEndDatetimeError("* Required");
        //     isValid = false;
        // } else {
        //     setEndDatetimeError("");
        // }

        if (!street) {
            setStreetError("* Required");
            isValid = false;
        } else {
            setStreetError("");
        }

        if (!zipCode) {
            setZipCodeError("* Required");
            isValid = false;
        } else {
            setZipCodeError("");
        }

        if (!city) {
            setCityError("* Required");
            isValid = false;
        } else {
            setCityError("");
        }

        if (!state) {
            setStateError("* Required");
            isValid = false;
        } else {
            setStateError("");
        }

        return isValid;
    };

    const isValidEmail = (email: string) => {
        // Implement your email validation logic here
        return /\S+@\S+\.\S+/.test(email);
    };



    function handleTabChange(event: React.SyntheticEvent, newValue: string) {
        setSelectedTab(newValue);
    };

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    function handleSiteUrlChange(event: any) {
        setSiteUrl(event.target.value);
    }

    function handleIsOrderingEnabledChange(event: any) {
        setIsOrderingEnabled(event.target.checked);
    }

    function handleIsMerchEnabledChange(event: any) {
        setIsMerchEnabled(event.target.checked);
    }

    function handleIsMapEnabledChange(event: any) {
        setIsMapEnabled(event.target.checked);
    }

    function handleIsScheduleEnabledChange(event: any) {
        setIsScheduleEnabled(event.target.checked);
    }

    function handleStartDatetimeChange(event: any) {
        setStartDatetime(event);
    }

    function handleEndDatetimeChange(event: any) {
        setEndDatetime(event);
    }

    function handleStreetChange(event: any) {
        setStreet(event.target.value);
    }

    function handleZipCodeChange(event: any) {
        setZipCode(event.target.value);
    }

    function handleCityChange(event: any) {
        setCity(event.target.value);
    }

    function handleStateChange(event: any, newValue: string | null) {
        setState(newValue);
    }

    function handleDeleteFestival() {
        props?.onDelete(props?.data);
    }

    function handleFestivalRestaurantDelete(e: any) {
        setOpenDeleteFestivalRestaurantConfirm(true);
        setToDeleteItem(e);
    }

    function handleArtistDelete(e: any) {
        setOpenDeleteArtistConfirm(true);
        setToDeleteArtistItem(e);
    }

    function handleStageDelete(e: any) {
        setOpenDeleteStageConfirm(true);
        setToDeleteStageItem(e);
    }

    function handlePerformanceDelete(e: any) {
        setOpenDeletePerformanceConfirm(true);
        setToDeletePerformanceItem(e);
    }

    function handleFestivalRestaurantClick(e: any) {
        setEditFestivalRestaurant(e);
        setOpenAddRestaurant(true);
    }

    function handleArtistClick(e: any) {
        setEditArtist(e);
        setOpenAddArtist(true);
    }

    function handleStageClick(e: any) {
        setEditStage(e);
        setOpenAddStage(true);
    }

    function handlePerformanceClick(e: any) {
        setEditPerformance(e);
        setOpenAddPerformance(true);
    }

    function handleOpenAddRestaurant() {
        setOpenAddRestaurant(true);
    }

    function handleOpenAddArtist() {
        setOpenAddArtist(true);
    }

    function handleOpenAddStage() {
        setOpenAddStage(true);
    }

    function handleOpenAddPerformance() {
        setOpenAddPerformance(true);
    }

    function handleFileChange(fileList: File[]) {
        setFileToUpload(fileList[0]);
        const objectUrl = URL.createObjectURL(fileList[0]);
        setThumbnail(objectUrl);
        // setPreview(objectUrl)

        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };

    function handleRemoveFestivalThumbnailClick(event: any) {
        event.stopPropagation();
        setFileToUpload(null);
        setThumbnail('');
    }

    function handleDeleteFestivalRestaurantConfirmClose(value: any) {
        if (value) {
            let _newFestivalRestaurants = festivalRestaurants.filter(
                fr => !(fr.festivalId === toDeleteItem.festivalId && fr.restaurantId === toDeleteItem.restaurantId)
            );
            setFestivalRestaurants([..._newFestivalRestaurants]);
        }

        setOpenDeleteFestivalRestaurantConfirm(false);
        setToDeleteItem(null);
    }

    function handleDeleteStageConfirmClose(value: any) {
        if (value) {
            let _newFestivalStages = festivalStages.filter(
                fr => !(fr.id === toDeleteStageItem.id)
            );
            setFestivalStages([..._newFestivalStages]);
        }

        setOpenDeleteStageConfirm(false);
        setToDeleteStageItem(null);
    }

    function handleDeleteArtistConfirmClose(value: any) {
        if (value) {
            let _newFestivalArtists = festivalArtists.filter(
                fr => !(fr.id === toDeleteArtistItem.id)
            );
            setFestivalArtists([..._newFestivalArtists]);
        }

        setOpenDeleteArtistConfirm(false);
        setToDeleteArtistItem(null);
    }

    function handleDeletePerformanceConfirmClose(value: any) {
        if (value) {
            let _newFestivalPerformances = festivalPerformances.filter(
                fr => !(fr.id === toDeletePerformanceItem.id)
            );
            setFestivalPerformances([..._newFestivalPerformances]);
        }

        setOpenDeletePerformanceConfirm(false);
        setToDeletePerformanceItem(null);
    }

    function handleAddRestaurantClose(value: any) {
        setOpenAddRestaurant(false);
        setEditFestivalRestaurant(null);


        if (value != null) {
            if (festivalRestaurants.find(fr => fr.restaurantId === value?.restaurantId && fr.festivalId === value?.festivalId)) {

                let newFestivalRestaurants = festivalRestaurants.map(fr => {
                    if (fr.restaurantId === value.restaurantId && fr.festivalId === value.festivalId) {
                        return ({ ...fr, ...value });
                    } else {
                        return fr;
                    }
                });
                setFestivalRestaurants([...newFestivalRestaurants]);
            } else {
                setFestivalRestaurants([...festivalRestaurants, ({
                    ...value,
                    restaurantName: restaurants.find(r => r.id === value.restaurantId)?.name
                })]);
            }
        }
    };

    // PROGRAM

    function handleAddArtistClose(value: any) {
        setOpenAddArtist(false);
        setEditArtist(null);

        if (value != null) {
            if (festivalArtists.find(fr => fr.id === value?.id)) {

                let newFestivalArtists = festivalArtists.map(fr => {
                    if (fr.id === value.id) {
                        return ({ ...fr, ...value });
                    } else {
                        return fr;
                    }
                });
                setFestivalArtists([...newFestivalArtists]);
            } else {
                setFestivalArtists([...festivalArtists, ({
                    ...value
                })]);
            }
        }
    };

    function handleAddStageClose(value: any) {
        setOpenAddStage(false);
        setEditStage(null);

        if (value != null) {
            if (festivalStages.find(fr => fr.id === value?.id && fr.festivalId === value?.festivalId)) {

                let newFestivalStages = festivalStages.map(fr => {
                    if (fr.id === value.id && fr.festivalId === value.festivalId) {
                        return ({ ...fr, ...value });
                    } else {
                        return fr;
                    }
                });
                setFestivalStages([...newFestivalStages]);
            } else {
                setFestivalStages([...festivalStages, ({
                    ...value
                })]);
            }
        }
    }

    function handleAddPerformanceClose(value: any) {
        setOpenAddPerformance(false);
        setEditPerformance(null);

        if (value != null) {
            if (festivalPerformances.find(fr => fr.id === value?.id)) {

                let newFestivalPerformances = festivalPerformances.map(fr => {
                    if (fr.id === value.id) {
                        return ({ ...fr, ...value });
                    } else {
                        return fr;
                    }
                });
                setFestivalPerformances([...newFestivalPerformances]);
            } else {
                setFestivalPerformances([...festivalPerformances, ({
                    ...value
                })]);
            }
        }
    }

    // MANAGER PROFILE

    function handleOwnerLoginChange(event: any) {
        setOwnerLogin(event.target.value);
    }

    function handleOwnerFirstNameChange(event: any) {
        setOwnerFirstName(event.target.value);
    }

    function handleOwnerLastNameChange(event: any) {
        setOwnerLastName(event.target.value);
    }

    function handleOwnerEmailChange(event: any) {
        setOwnerEmail(event.target.value);
    }

    function handleOwnerPhoneChange(event: any) {
        setOwnerPhone(event.target.value);
    }

    function handleOwnerStreetChange(event: any) {
        setOwnerStreet(event.target.value);
    }

    function handleOwnerZipCodeChange(event: any) {
        setOwnerZipCode(event.target.value);
    }

    function handleOwnerCityChange(event: any) {
        setOwnerCity(event.target.value);
    }

    function handleOwnerStateChange(event: any, newValue: string | null) {
        setOwnerState(newValue);
    }

    return (
        <>
            {openAddArtist && <AddArtistDialog
                open={openAddArtist}
                onClose={handleAddArtistClose}
                artists={artists}
                festivalId={props.data.id}
                artist={editArtist}
                noLabel={t('containers.editors.festival.dialog.actions.cancel')}
                yesLabel={t('containers.editors.festival.dialog.actions.save')}
            />}

            {openAddStage && <AddStageDialog
                open={openAddStage}
                onClose={handleAddStageClose}
                stages={festivalStages}
                festivalId={props.data.id}
                stage={editStage}
                noLabel={t('containers.editors.festival.dialog.actions.cancel')}
                yesLabel={t('containers.editors.festival.dialog.actions.save')}
            />}

            {openAddPerformance && <AddPerformanceDialog
                open={openAddPerformance}
                onClose={handleAddPerformanceClose}
                artists={artists}
                stages={festivalStages}
                festivalId={props.data.id}
                performance={editPerformance}
                performances={festivalPerformances}
                noLabel={t('containers.editors.festival.dialog.actions.cancel')}
                yesLabel={t('containers.editors.festival.dialog.actions.save')}
            />}

            {openAddRestaurant && <AddRestaurantDialog
                open={openAddRestaurant}
                onClose={handleAddRestaurantClose}
                restaurants={restaurants}
                festivalId={props.data.id}
                id={editFestivalRestaurant?.id}
                restaurantId={editFestivalRestaurant?.restaurantId}
                isRecommended={editFestivalRestaurant?.isRecommended}
                location={editFestivalRestaurant?.location}
                noLabel={t('containers.editors.festival.dialog.actions.cancel')}
                yesLabel={t('containers.editors.festival.dialog.actions.save')}
            />}

            {openDeleteFestivalRestaurantConfirm && <ConfirmDialog
                open={openDeleteFestivalRestaurantConfirm}
                onClose={handleDeleteFestivalRestaurantConfirmClose}
                title={t('containers.editors.festival.dialog.titles.remove')}
                content={t('containers.editors.festival.dialog.contents.remove')}
                noLabel={t('containers.editors.festival.dialog.actions.keep')}
                yesLabel={t('containers.editors.festival.dialog.actions.delete')}
            />}

            {openDeleteStageConfirm && <ConfirmDialog
                open={openDeleteStageConfirm}
                onClose={handleDeleteStageConfirmClose}
                title={t('containers.editors.festival.dialog.titles.remove')}
                content={t('containers.editors.festival.dialog.contents.removeStage')}
                noLabel={t('containers.editors.festival.dialog.actions.keep')}
                yesLabel={t('containers.editors.festival.dialog.actions.delete')}
            />}

            {openDeleteArtistConfirm && <ConfirmDialog
                open={openDeleteArtistConfirm}
                onClose={handleDeleteArtistConfirmClose}
                title={t('containers.editors.festival.dialog.titles.remove')}
                content={t('containers.editors.festival.dialog.contents.removeArtist')}
                noLabel={t('containers.editors.festival.dialog.actions.keep')}
                yesLabel={t('containers.editors.festival.dialog.actions.delete')}
            />}

            {openDeletePerformanceConfirm && <ConfirmDialog
                open={openDeletePerformanceConfirm}
                onClose={handleDeletePerformanceConfirmClose}
                title={t('containers.editors.festival.dialog.titles.remove')}
                content={t('containers.editors.festival.dialog.contents.removeArtist')}
                noLabel={t('containers.editors.festival.dialog.actions.keep')}
                yesLabel={t('containers.editors.festival.dialog.actions.delete')}
            />}


            <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Info" value="1" />
                        <Tab label="Program" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Stack spacing={2}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.name')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={name}
                                    onChange={handleNameChange}
                                    error={!!nameError}
                                    helperText={nameError}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Typography><strong>{t('containers.editors.festival.labels.description')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Typography><strong>{t('containers.editors.festival.labels.image')}</strong></Typography>
                                <FileUploading value={fileToUpload != null ? [fileToUpload] : []} onChange={handleFileChange}
                                    acceptType={['jpg', 'jpeg', 'png']} maxFileSize={2097152}>
                                    {({
                                        fileList,
                                        errors,
                                        isDragging,
                                        onFileUpload,
                                        onFileRemoveAll,
                                        onFileUpdate,
                                        onFileRemove,
                                        dragProps,
                                    }) => {
                                        return (
                                            <>
                                                <Box
                                                    id="btn-upload"
                                                    onClick={onFileUpload}
                                                    {...dragProps}
                                                    sx={styles.fileUploadContainer}
                                                >
                                                    {errors && errors.maxNumber && (
                                                        <span>Number of selected files exceed maxNumber</span>
                                                    )}

                                                    <>
                                                        <Stack
                                                            spacing={2}
                                                            direction="column"
                                                            justifyContent="space-between"
                                                            sx={styles.fileUploadLabelContainer}
                                                        >
                                                            <Typography noWrap sx={styles.fileUploadLabel}>
                                                                {t('containers.editors.restaurant.labels.chooseImage')}
                                                            </Typography>

                                                            {fileToUpload != null && (
                                                                <Stack
                                                                    spacing={2}
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Typography
                                                                        sx={styles.fileName}
                                                                    >
                                                                        {fileToUpload?.name}

                                                                    </Typography>

                                                                    <DeleteIcon
                                                                        onClick={(e) => handleRemoveFestivalThumbnailClick(e)} />
                                                                </Stack>)}
                                                        </Stack>
                                                        <img
                                                            style={{
                                                                filter: "brightness(70%)",
                                                                borderRadius: "5px",
                                                                height: "100%",
                                                                width: "100%",
                                                                objectFit: "cover"
                                                            }}
                                                            src={thumbnail}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = require("../../../../src/assets/festival_placeholder.png");
                                                            }}
                                                        />

                                                    </>

                                                </Box>
                                            </>
                                        );
                                    }}
                                </FileUploading>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.siteUrl')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={siteUrl}
                                    onChange={handleSiteUrlChange}
                                    error={!!siteUrlError}
                                    helperText={siteUrlError}
                                />
                            </Grid>


                            {props?.showModules && <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.isOrderingEnabled')}</strong></Typography>
                                <Checkbox onChange={handleIsOrderingEnabledChange} checked={isOrderingEnabled} />

                                <Typography><strong>{t('containers.editors.festival.labels.isMerchEnabled')}</strong></Typography>
                                <Checkbox onChange={handleIsMerchEnabledChange} checked={isMerchEnabled} />

                                <Typography><strong>{t('containers.editors.festival.labels.isMapEnabled')}</strong></Typography>
                                <Checkbox onChange={handleIsMapEnabledChange} checked={isMapEnabled} />

                                <Typography><strong>{t('containers.editors.festival.labels.isScheduleEnabled')}</strong></Typography>
                                <Checkbox onChange={handleIsScheduleEnabledChange} checked={isScheduleEnabled} />

                            </Grid>}

                            <Grid item xs={6}>
                                <Typography><strong>{t('containers.editors.festival.labels.startDate')}</strong></Typography>
                                <DatePicker
                                    value={startDatetime}
                                    onChange={handleStartDatetimeChange}
                                // error={!!startDatetimeError}
                                // helperText={startDatetimeError}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>{t('containers.editors.festival.labels.endDate')}</strong></Typography>
                                <DatePicker
                                    value={endDatetime}
                                    onChange={handleEndDatetimeChange}
                                // error={!!endDatetimeError}
                                // helperText={endDatetimeError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.street')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={street}
                                    onChange={handleStreetChange}
                                    error={!!streetError}
                                    helperText={streetError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.zipCode')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={zipCode}
                                    onChange={handleZipCodeChange}
                                    error={!!zipCodeError}
                                    helperText={zipCodeError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.city')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={city}
                                    onChange={handleCityChange}
                                    error={!!cityError}
                                    helperText={cityError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.festival.labels.country')}</strong></Typography>
                                <Autocomplete
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    options={countries}
                                    value={state}
                                    onChange={handleStateChange}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label} ({option.code})
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!stateError}
                                            helperText={stateError}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                        <Typography>
                                            <strong>{t('containers.editors.festival.labels.restaurants')}</strong>
                                        </Typography>

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={handleOpenAddRestaurant}
                                        >
                                            +
                                        </Button>
                                    </Stack>

                                    {festivalRestaurants.map((fr: any, index: number) => (
                                        <FestivalRestaurantItem
                                            key={index}
                                            festivalRestaurant={fr}
                                            onDelete={handleFestivalRestaurantDelete}
                                            onClick={handleFestivalRestaurantClick}
                                        />
                                    ))}
                                </>
                            </Grid>

                            {props?.action === 'CREATE' && <>
                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.userName')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerLogin}
                                        onChange={handleOwnerLoginChange}
                                        error={!!ownerLoginError}
                                        helperText={ownerLoginError}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography><strong>{t('containers.editors.profile.labels.firstName')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerFirstName}
                                        onChange={handleOwnerFirstNameChange}
                                        error={!!ownerFirstNameError}
                                        helperText={ownerFirstNameError}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography><strong>{t('containers.editors.profile.labels.lastName')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerLastName}
                                        onChange={handleOwnerLastNameChange}
                                        error={!!ownerLastNameError}
                                        helperText={ownerLastNameError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.email')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerEmail}
                                        onChange={handleOwnerEmailChange}
                                        error={!!ownerEmailError}
                                        helperText={ownerEmailError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.phoneNumber')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerPhone}
                                        onChange={handleOwnerPhoneChange}
                                        error={!!ownerPhoneError}
                                        helperText={ownerPhoneError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.street')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerStreet}
                                        onChange={handleOwnerStreetChange}
                                        error={!!ownerStreetError}
                                        helperText={ownerStreetError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.zipCode')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerZipCode}
                                        onChange={handleOwnerZipCodeChange}
                                        error={!!ownerZipCodeError}
                                        helperText={ownerZipCodeError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.city')}</strong></Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        value={ownerCity}
                                        onChange={handleOwnerCityChange}
                                        error={!!ownerCityError}
                                        helperText={ownerCityError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography><strong>{t('containers.editors.profile.labels.country')}</strong></Typography>
                                    <Autocomplete
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        options={countries}
                                        value={ownerState}
                                        onChange={handleOwnerStateChange}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={!!ownerStateError}
                                                helperText={ownerStateError}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>}
                        </Grid>

                        {props?.action === 'UPDATE' && <>
                            <Divider />

                            <Button
                                color="black"
                                variant="text"
                                onClick={handleDeleteFestival}
                            >
                                {t('containers.editors.festival.actions.delete')}
                            </Button>
                        </>}
                    </Stack>
                </TabPanel>
                <TabPanel value="2">

                    <Stack spacing={2}>
                        <Grid item xs={12}>
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Typography>
                                        {/* <strong>{t('containers.editors.festival.labels.restaurants')}</strong> */}
                                        <strong>Stages</strong>
                                    </Typography>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={handleOpenAddStage}
                                    >
                                        +
                                    </Button>
                                </Stack>

                                {festivalStages.map((stage: any, index: number) => (
                                    <StageItem
                                        key={index}
                                        stage={stage}
                                        onDelete={handleStageDelete}
                                        onClick={handleStageClick}
                                    />
                                ))}
                            </>
                        </Grid>

                        {/* <Grid item xs={12}>
                        <>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>
                                    <strong>Artists</strong>
                                </Typography>

                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={handleOpenAddArtist}
                                >
                                    +
                                </Button>
                            </Stack>

                            {festivalArtists.map((artist: any, index: number) => (
                                <ArtistItem
                                    key={index}
                                    artist={artist}
                                    onDelete={handleArtistDelete}
                                    onClick={handleArtistClick}
                                />
                            ))}
                        </>
                    </Grid> */}


                        <Grid item xs={12}>
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Typography>
                                        {/* <strong>{t('containers.editors.festival.labels.restaurants')}</strong> */}
                                        <strong>Performances</strong>
                                    </Typography>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={handleOpenAddPerformance}
                                    >
                                        +
                                    </Button>
                                </Stack>

                                {festivalPerformances.map((performance: any, index: number) => (
                                    <PerformanceItem
                                        key={index}
                                        performance={performance}
                                        onDelete={handlePerformanceDelete}
                                        onClick={handlePerformanceClick}
                                    />
                                ))}
                            </>
                        </Grid>
                    </Stack>
                </TabPanel>
            </TabContext>
        </>
    );
}

const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '125px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100px'
    }
}