import { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, styled, useTheme } from '@mui/material';
import { ExtraMenuitem } from '../../shared/interfaces/extra-menuitem.interface';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { ExtraCategory } from '../../shared/interfaces/extra-category.interface';
import { newGuid } from '../../core/utilities';
import { useTranslation } from 'react-multi-lang';


const TaxToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    justifyContent: "start",
    gridGap: "10px",
    padding: "10px",
    ".MuiToggleButtonGroup-grouped": {
        border: '1px solid #EBEBF0 !important',
        borderRadius: '4px !important'
    }
}));

export interface ExtraMenuItemEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: ExtraMenuitem;
    extraCategory: ExtraCategory | null;
    onChangeData: (data: any) => void;
    onDelete: (id: string) => void;
}

export default function ExtraMenuItemEditor(props: ExtraMenuItemEditorProps) {

    const theme = useTheme();

    const [id, setId] = useState(props?.data?.id);
    const [name, setName] = useState(props?.data?.name || "");
    // const [description, setDescription] = useState(props?.extraMenuitem?.description || "");
    const [weight, setWeight] = useState(parseWeight(props?.data?.weight));
    const [price, setPrice] = useState(props?.data?.price || 0);
    const [taxRate, setTaxRate] = useState(props?.data?.taxRate || 0);
    const [weightUnit, setWeightUnit] = useState(parseWeightUnit(props?.data?.weight) || "g");
    const [existingExtraMenuitems, setExistingExtraMenuitems] = useState<ExtraMenuitem[]>([]);
    const [selectedExitingExtraMenuitem, setSelectedExitingExtraMenuitem] = useState<string>('');
    const [pristine, setPristine] = useState<boolean>(true);

    const [nameError, setNameError] = useState("");
    const [weightError, setWeightError] = useState("");

    const axiosSecure = useAxiosSecure();
    const t = useTranslation();
    const auth = useSelector((state: RootState) => state.auth);
    const restaurant = useSelector((state: RootState) => state.auth.restaurant);


    useEffect(() => {
        apiCall();
    }, [])


    useEffect(() => {
        setId(props?.data?.id);
        setName(props?.data?.name || "");
        setWeight(parseWeight(props?.data?.weight));
        setPrice(props?.data?.price || 0);
        setTaxRate(props?.data?.taxRate || 0);
        setWeightUnit(parseWeightUnit(props?.data?.weight) || "g");
        apiCall();
    }, [props?.data])


    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (!weight) {
            setWeightError("* Required");
            isValid = false;
        } else {
            setWeightError("");
        }

        return isValid;
    };

    useEffect(() => {

        // if there was an change, chreate new ID
        props?.onChangeData({
            isValid: validateForm(),
            id: props?.action === 'UPDATE' ? id : (pristine ? id : newGuid()),
            name,
            description: "",
            weight: `${weight}${weightUnit}`,
            price,
            taxRate,
            viewOrder: props?.data?.viewOrder || 0,
            isHidden: false,
            _isExisting: props?.action === 'UPDATE' ? true : pristine
        });

    }, [name, weight, weightUnit, price, taxRate])


    async function apiCall() {

        let filter = "";

        // TODO
        // if (auth.user) {
        //     filter += `restaurantId=${auth.user?.restaurantId}&`;
        // }

        let response = await axiosSecure.get(`/api/extra_menuitem?${filter}`, false);

        let extistingIds = props?.extraCategory?.extraCategoryExtraMenuitems.map(e => e.extraMenuitemId);

        setExistingExtraMenuitems(response.data?.filter((m: ExtraMenuitem) => extistingIds?.indexOf(m.id) === -1));
    }

    function handleNameChange(event: any) {
        setName(event.target.value);
        setPristine(false);
    }

    function handlePriceChange(event: any) {
        var value = event.target.value?.replace(/[^0-9$.,]/g, '')
        setPrice(value);
        setPristine(false);
    }

    const handleTaxRateChange = (
        event: React.MouseEvent<HTMLElement>,
        newTaxRate: number | null,
    ) => {
        if (newTaxRate != null) {
            setTaxRate(newTaxRate)
        };
    };

    function handleWeightChange(event: any) {
        var value = event.target.value?.replace(/[^0-9$.,]/g, '')
        setWeight(value);
        setPristine(false);
    }

    function handleWeightUnitChange(event: any) {
        setWeightUnit(event.target.value);
        setPristine(false);
    }

    function parseWeight(weight: string | undefined) {
        var res = weight?.replace(/[^\d.-]/g, "");
        return res || "";
    }

    function parseWeightUnit(weight: string | undefined) {
        var res = weight?.replace(/[\W\d]/g, "");
        return res || "";
    }

    function handleExistingExtraMenuitemChange(event: SelectChangeEvent) {
        setSelectedExitingExtraMenuitem(event.target.value as string);

        if (event.target.value) {

            const extraMenuitem = existingExtraMenuitems.find(e => e.id === event.target.value) as ExtraMenuitem;

            setId(extraMenuitem.id)
            setName(extraMenuitem.name);
            setPrice(extraMenuitem.price);
            setTaxRate(extraMenuitem.taxRate);
            setWeight(parseWeight(extraMenuitem.weight));
            setWeightUnit(parseWeightUnit(extraMenuitem.weight) || "g");
            setPristine(true);


            props?.onChangeData({
                id: extraMenuitem?.id,
                name: extraMenuitem?.name,
                description: "",
                weight: extraMenuitem?.weight,
                price: extraMenuitem?.price,
                taxRate: extraMenuitem?.taxRate,
                viewOrder: 0,
                isHidden: false,
                _isExisting: props?.action === 'UPDATE' ? true : pristine
            });

        } else {
            setId(props?.data?.id);
            setName("");
            setPrice(0);
            setTaxRate(0);
            setWeight(parseWeight(""));
            setWeightUnit("g");
            setPristine(true);
        }
    }

    function handleDeleteExtraMenuitem() {
        props?.onDelete(props?.data?.id);
    }

    return (
        <Box sx={styles.container}>
            <Stack spacing={2}>

                {props?.action === 'CREATE' && <>
                    <FormControl fullWidth>
                        <InputLabel>{t('containers.editors.extraMenuitem.labels.existing')}</InputLabel>
                        <Select
                            value={selectedExitingExtraMenuitem}
                            onChange={handleExistingExtraMenuitemChange}
                        >
                            <MenuItem key={''} value={''}>
                                {t('containers.editors.extraMenuitem.labels.new')}
                            </MenuItem>

                            {existingExtraMenuitems.map((extraMenuitem, index) => (
                                <MenuItem key={extraMenuitem.id} value={extraMenuitem.id}>
                                    {extraMenuitem.name} ({extraMenuitem.weight}) - {extraMenuitem.price}€
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>}

                {/* {selectedExitingExtraMenuitem === '' &&  */}
                <>
                    <TextField
                        sx={styles.white}
                        label={t('containers.editors.extraMenuitem.labels.name')}
                        variant="outlined"
                        value={name}
                        onChange={handleNameChange}
                        error={!!nameError}
                        helperText={nameError}
                    />

                    <RadioGroup
                        row
                        value={weightUnit}
                        onChange={handleWeightUnitChange}
                    >
                        <Stack direction="row" spacing={2}>
                            <TextField
                                sx={styles.white}
                                label={t('containers.editors.extraMenuitem.labels.size')}
                                value={weight}
                                variant="outlined"
                                onChange={handleWeightChange}
                                error={!!weightError}
                                helperText={weightError}
                            />
                            <div>
                                <FormControlLabel value="g" control={<Radio />} label="gramov" />
                                <FormControlLabel value="ml" control={<Radio />} label="mililitrov" />
                            </div>
                        </Stack>
                    </RadioGroup>

                    <TextField
                        sx={styles.white}
                        label={t('containers.editors.extraMenuitem.labels.price')}
                        variant="outlined"
                        value={price}
                        onChange={handlePriceChange}
                    />

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.menuitem.labels.taxRate')}</strong></Typography>
                        <TaxToggleButtonGroup
                            value={taxRate}
                            exclusive
                            onChange={handleTaxRateChange}
                        >
                            {restaurant?.taxRates.map((tax, i) => (
                                <ToggleButton
                                    key={tax.id}
                                    sx={styles.taxButton}
                                    color="primary"
                                    value={tax.taxRate}
                                >
                                    {`${tax.taxRate} %`}
                                </ToggleButton>
                            ))}
                        </TaxToggleButtonGroup>
                    </Grid>
                </>
                {/* } */}


                {props?.action === 'UPDATE' && <>
                    <Divider />

                    <Button
                        color="black"
                        variant="text"
                        onClick={handleDeleteExtraMenuitem}
                    >
                        {t('containers.editors.extraMenuitem.actions.delete')}
                    </Button>
                </>}

            </Stack>
        </Box>
    );
}


const styles = {
    container: {
        width: '100%',
        marginTop: '20px'
    },
    white: {
        background: 'white'
    },
    taxButton: {
        width: "fit-content",
        height: "20px"
    }
}