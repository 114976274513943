import { Card, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { setPanelContext } from "../../core/store/features/sidePanel/sidePanelSlice";
import { useDispatch } from "react-redux";
import { Festival } from "../../shared/interfaces/festival.interface";
import { MINIO_URL } from "../../core/api/axios";


export interface FestivalItemProps {
    festival: Festival;
}

export default function FestivalItem(props: FestivalItemProps) {

    const theme = useTheme();
    const t = useTranslation()
    const dispatch = useDispatch();
    const [thumbnail, setThumbnail] = useState<string>("../../../../src/assets/festival_placeholder.png");


    // useEffect(() => {
    //     setThumbnail(`${MINIO_URL}/festivals/${props?.festival?.id}`);
    // }, [props?.festival])

    const handleClick = () => {
        dispatch(setPanelContext({
            action: 'UPDATE',
            contextType: 'Festival',
            contextObject: props?.festival
        }));
    };

    return (
        <>
            <Card sx={styles.container}
                onClick={handleClick}
            >
                {/* <CardMedia
                    component="img"
                    height="195"
                    image={thumbnail}
                    alt={props?.festival?.name}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require("../../../../src/assets/festival_placeholder.png");
                    }}
                /> */}
                <CardContent
                    sx={{
                        height: "100%"
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {props?.festival?.name}
                    </Typography>

                </CardContent>
            </Card>
        </>
    );
}

const styles = {
    container: {
        // aspectRatio: "1/ 1",
        // maxHeight: "300px",
        borderRadius: "10px",
        textAlign: "center",
        margin: "5px",
        display: 'flex',
        justiyContent: 'space-between',
        flexDirection: 'column',
        boxShadow: '0px 2px 6px rgba(13, 10, 44, 0.08)',
        ':hover': {
            boxShadow: 15,
            cursor: 'pointer'
        }
    }
}