import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useFormInputValidation } from "react-form-input-validation";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { login } from "../../app/core/store/features/auth/authService";
import { SecurityRole } from "../../app/core/security/SecurityRole";
import { updateAuth } from "../../app/core/store/features/auth/authSlice";
import LanguageSelect from "../../app/components/LanguageSelect/LanguageSelect";
import { RootState } from "../../app/core/store/store";


export default function Login() {

    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const t = useTranslation();


    const [fields, errors, form] = useFormInputValidation({
        login: "",
        password: "",
    }, {
        login: "required",
        password: "required"
    });

    async function onSubmit(event: any) {

        const isValid = await form.validate(event);
        if (isValid) {
            const response = await login({
                login: (fields as any).login,
                password: (fields as any).password
            });

            const accessToken = response?.accessToken;
            const refreshToken = response?.refreshToken;
            const user = response?.user;
            const restaurant = user?.restaurant;
            const festival = user?.festival;

            dispatch(updateAuth({
                accessToken,
                refreshToken,
                user,
                restaurant,
                festival
            }));
        }
    }

    useEffect(() => {
        let isLoggedIn = auth.user !== null;

        if (isLoggedIn) {
            if (auth.user?.securityRoleId === SecurityRole.Admin) {
                navigate("/admin");
            } else {
                navigate("/overview");
            }
        }
    })

    return (
        <>
            <Stack
                spacing={2}
                alignItems="center"
                sx={styles.container}
            >
                <Stack>
                    <LanguageSelect
                        text={t('languageSelect.language')}
                        open={true}
                        onMouseOver={() => { }}
                        onMouseOut={() => { }}
                    />
                </Stack>

                <Box
                    component="form"
                    noValidate
                    onSubmit={onSubmit}
                    autoComplete="off"
                >
                    <Stack spacing={2} alignItems="center">

                        <TextField
                            required
                            label={t('pages.login.labels.userName')}
                            name="login"
                            variant="outlined"
                            onBlur={form.handleBlurEvent}
                            onChange={form.handleChangeEvent}
                            value={(fields as any).login}
                        />
                        <Typography
                            className="error"
                            sx={styles.bold}
                        >
                            {(errors as any).login
                                ? (errors as any).login
                                : ""}
                        </Typography>

                        <TextField
                            required
                            label={t('pages.login.labels.password')}
                            name="password"
                            type="password"
                            variant="outlined"
                            onBlur={form.handleBlurEvent}
                            onChange={form.handleChangeEvent}
                            value={(fields as any).password}
                        />
                        <Typography
                            className="error"
                            sx={styles.bold}
                        >
                            {(errors as any).password
                                ? (errors as any).password
                                : ""}
                        </Typography>

                        {/* <input type="submit" value="Submit" /> */}
                        <Button variant="contained" type="submit" value="Submit">
                            {t('pages.login.actions.login')}
                        </Button>


                        <Typography>
                            <Link href="/forget-password">{t('pages.login.actions.forgetPassword')}</Link>
                        </Typography>


                        {/* <Typography>
                            {t('pages.login.labels.register')} <Link href="/register">{t('pages.login.actions.register')}</Link>
                        </Typography>

                        <Typography>
                            <Link href="/festival/register">{t('pages.login.actions.registerFestival')}</Link>
                        </Typography>

                        <Typography>
                            <Link href="/restaurant/register">{t('pages.login.actions.registerRestaurant')}</Link>
                        </Typography> */}
                    </Stack>
                </Box>
            </Stack>
        </>

    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bold: {
        fontSize: 'small',
        fontWeight: 'bold'
    }
}