import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Restaurant } from '../../shared/interfaces/restaurant.interface';
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-multi-lang';
import { useEffect, useState } from 'react';
import { newGuid } from '../../core/utilities';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface AddRestaurantDialogProps {
    open: boolean;
    yesLabel: string;
    noLabel: string;
    festivalId: string;
    id?: string;
    restaurantId?: string;
    isRecommended?: boolean;
    location?: string;
    restaurants: Restaurant[];
    onClose: (e: any) => void;
}

export default function AddRestaurantDialog(props: AddRestaurantDialogProps) {

    const { onClose, open } = props;
    const [id, setId] = useState("");
    const [location, setLocation] = useState("");
    const [isRecommended, setIsRecommended] = useState(false);
    const [selectedRestaurantId, setSelectedRestaurantId] = useState("");

    const [locationError, setLocationError] = useState("");
    const [selectedRestaurantIdError, setSelectedRestaurantIdError] = useState("");

    const t = useTranslation();

    useEffect(() => {
        // update data
        setId(props?.id || "");
        setLocation(props?.location || "");
        setIsRecommended(props?.isRecommended || false);
        setSelectedRestaurantId(props?.restaurantId || "");
    }, [props])

    useEffect(() => {
        validateForm();
    }, [location, selectedRestaurantId])

    const handleOkClose = () => {
        if (validateForm()) {
            onClose({
                id: props?.id ? id : newGuid(),
                festivalId: props?.festivalId,
                restaurantId: selectedRestaurantId,
                location: location,
                isRecommended: isRecommended
            });
        }
    };

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!location) {
            setLocationError("* Required");
            isValid = false;
        } else {
            setLocationError("");
        }

        if (!selectedRestaurantId) {
            setSelectedRestaurantIdError("* Required");
            isValid = false;
        } else {
            setSelectedRestaurantIdError("");
        }

        return isValid;
    };

    const handleCancelClose = () => {
        onClose(null);
    };

    function handleLocationChange(event: any) {
        setLocation(event.target.value);
    }

    function handleSelectedRestaurantChange(event: SelectChangeEvent) {
        setSelectedRestaurantId(event.target.value as string);
    }

    function handleIsRecommendedChange(event: any) {
        setIsRecommended(event.target.checked);
    }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props?.id ? t('dialogs.titles.updateRestaurantLocation') : t('dialogs.titles.addRestaurantLocation')}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    sx={styles.content}
                >
                    <Grid item xs={12}>
                        <FormControl
                            disabled={props?.id ? true : false}
                            error={!!selectedRestaurantIdError}
                            size="small"
                            sx={{
                                width: '100%'
                            }}
                        >
                            <InputLabel>{t('dialogs.labels.restaurant')}</InputLabel>
                            <Select
                                value={selectedRestaurantId}
                                label={t('dialogs.labels.restaurant')}
                                onChange={handleSelectedRestaurantChange}
                            >
                                {props?.restaurants?.map((restaurant) => (
                                    <MenuItem key={restaurant.id} value={restaurant.id}>
                                        <ListItemText primary={restaurant.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {!!selectedRestaurantIdError && <Typography
                                sx={{
                                    color: '#d32f2f',
                                    fontWeight: 400,
                                    fontSize: '0.75rem',
                                    lineHeight: 1.66,
                                    textAlign: 'left',
                                    marginTop: '3px',
                                    marginRight: '14px',
                                    marginBottom: 0,
                                    marginLeft: '14px'
                                }}
                            >{selectedRestaurantIdError}</Typography>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.location')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            value={location}
                            onChange={handleLocationChange}
                            error={!!locationError}
                            helperText={locationError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('dialogs.labels.isRecommended')}</strong></Typography>
                        <Checkbox onChange={handleIsRecommendedChange} checked={isRecommended} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="black" variant='contained' size="small" onClick={handleCancelClose}>{props?.noLabel || 'Cancel'}</Button>
                <Button variant='contained' size="small" onClick={handleOkClose}>{props?.yesLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog >
    );
}

const styles = {
    content: {
        width: '400px',
        height: '250px'
    }
}