import { Button, IconButton, Stack, Theme, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { newGuid } from '../../core/utilities';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-multi-lang';
import { setPanelContext } from '../../core/store/features/sidePanel/sidePanelSlice';


export type MenuNavBarProps = {

};

export default function MenuNavBar(props: MenuNavBarProps) {

    const location = useLocation();
    const theme = useTheme();
    const styles = makeStyles(theme);
    const dispatch = useDispatch();
    const t = useTranslation();

    const linkBreakpoints = {
        fontSize: {
            xs: '1rem',
            sm: '0.9rem',
            md: '1rem',
            lg: '1rem',
            xl: '1rem',
        }
    }

    function handleCreateCategory() {

        if (location?.pathname === '/standing-offer') {
            dispatch(setPanelContext({
                action: 'CREATE', contextType: 'Category', contextObject: {
                    id: newGuid(),
                    isStandingOffer: location?.pathname === '/standing-offer' ? true : false
                }
            }));
        } else {
            dispatch(setPanelContext({
                action: 'CREATE', contextType: 'ExtraCategory', contextObject: {
                    id: newGuid()
                }
            }));
        }
    }

    return (
        <Stack
            sx={styles.container}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                spacing={2}
            >
                <Typography
                    className="link"
                    component={NavLink}
                    to='/standing-offer'
                    sx={styles.link}
                >
                    <strong>{t('containers.layout.labels.standingOffer')}</strong>
                </Typography>

                <Typography
                    className="link"
                    component={NavLink}
                    to='/modifications'
                    sx={styles.link}
                >
                    <strong>{t('containers.layout.labels.modifications')}</strong>
                </Typography>
            </Stack>

            {['/standing-offer', '/modifications'].indexOf(location?.pathname) > -1 && <Button
                color="black"
                variant="contained"
                onClick={handleCreateCategory}
                sx={styles.createButton}
            >
                {t('containers.layout.actions.create')}
            </Button>}

            {['/standing-offer', '/modifications'].indexOf(location?.pathname) > -1 && <IconButton
                color="black"
                size='small'
                onClick={handleCreateCategory}
                sx={styles.createButtonSmall}
            >
                <AddIcon />
            </IconButton>}
        </Stack>
    );
}


const makeStyles = (theme: Theme) => {
    return {
        container: {
            height: '60px',
            padding: `0px 10px 0px calc(${theme.spacing(13)} + 1px)`,
            borderBottom: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0'
        },
        link: {
            fontSize: {
                xs: '1rem',
                sm: '0.9rem',
                md: '1rem',
                lg: '1rem',
                xl: '1rem',
            }
        },
        createButton: {
            display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block'
            },
            fontSize: {
                xs: '0.7rem',
                sm: '0.7rem',
                md: '0.7rem',
                lg: '0.7rem',
                xl: '1rem',
            }
        },
        createButtonSmall: {
            display: {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none'
            }
        }
    }
}