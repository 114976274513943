import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Alert, AlertTitle, Autocomplete, Button, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, Paper, Stack, styled, Tab, TextField, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploading from 'react-files-uploading';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { useTranslation } from 'react-multi-lang';
import { MINIO_URL } from '../../core/api/axios';
import { Restaurant } from '../../shared/interfaces/restaurant.interface';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { Cuisine } from '../../shared/interfaces/cuisine.interface';
import countries from '../../../assets/countries.json';
import { TaxRate } from '../../shared/interfaces/tax-rate.interface';
import { isNumber, newGuid } from '../../core/utilities';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FileUpload from '../../components/FileUpload/FileUpload';
import MultiFileUpload from '../../components/MultiFileUpload/MultiFileUpload';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/css";
import { MuiColorInput } from 'mui-color-input';


export interface RestaurantEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: Restaurant;
    showIsPopular: boolean;
    showDelete: boolean;
    onChangeData: (data: any) => void;
    onDelete: (e: Restaurant) => void;
}

export default function RestaurantEditor(props: RestaurantEditorProps) {

    const theme = useTheme();

    const [name, setName] = useState(props?.data?.name || "");
    const [description, setDescription] = useState(props?.data?.description || "");
    const [email, setEmail] = useState(props?.data?.email || "");
    const [isPopular, setIsPopular] = useState(props?.data?.isPopular || false);
    const [phone, setPhone] = useState(props?.data?.phone || "");
    const [street, setStreet] = useState(props?.data?.street || "");
    const [zipCode, setZipCode] = useState(props?.data?.zipCode || "");
    const [city, setCity] = useState(props?.data?.city || "");
    const [state, setState] = useState<any>(countries[0] || null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [login, setLogin] = useState("");
    const [cuisines, setCuisines] = useState<Cuisine[]>([]);
    const [taxRates, setTaxRates] = useState<string>("");
    const [kioskColor, setKioskColor] = useState("#D92121");
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [logoFileToUpload, setLogoFileToUpload] = useState<File | null>(null);
    const [thumbnail, setThumbnail] = useState<string>("../../../../src/assets/restaurant_placeholder.png");
    const [logo, setLogo] = useState<string>("../../../../src/assets/restaurant_placeholder.png");
    const [homeKioskImages, setHomeKioskImages] = useState<File[] | null>(null);
    const [menuKioskImages, setMenuKioskImages] = useState<File[] | null>(null);


    const [clearHomeKioskImages, setClearHomeKioskImages] = useState<boolean>(false);
    const [clearMenuKioskImages, setClearMenuKioskImages] = useState<boolean>(false);

    const [currentHomeKioskImages, setCurrentHomeKioskImages] = useState<any[]>([]);
    const [currentMenuKioskImages, setCurrentMenuKioskImages] = useState<any[]>([]);

    const [allCuisines, setAllCuisines] = useState<Cuisine[]>([]);

    const [loginError, setLoginError] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [taxRatesError, setTaxRatesError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [streetError, setStreetError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");


    // OWNER PROFILE
    const [ownerLogin, setOwnerLogin] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerFirstName, setOwnerFirstName] = useState("");
    const [ownerLastName, setOwnerLastName] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [ownerStreet, setOwnerStreet] = useState("");
    const [ownerZipCode, setOwnerZipCode] = useState("");
    const [ownerCity, setOwnerCity] = useState("");
    const [ownerState, setOwnerState] = useState<any>(countries[0] || null);

    const [ownerLoginError, setOwnerLoginError] = useState("");
    const [ownerEmailError, setOwnerEmailError] = useState("");
    const [ownerFirstNameError, setOwnerFirstNameError] = useState("");
    const [ownerLastNameError, setOwnerLastNameError] = useState("");
    const [ownerPhoneError, setOwnerPhoneError] = useState("");
    const [ownerStreetError, setOwnerStreetError] = useState("");
    const [ownerZipCodeError, setOwnerZipCodeError] = useState("");
    const [ownerCityError, setOwnerCityError] = useState("");
    const [ownerStateError, setOwnerStateError] = useState("");

    const [selectedTab, setSelectedTab] = useState('1');

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();

    // TODO: load images
    async function apiCall() {
        let response = await axiosSecure.get(`/api/cuisine`, false);
        setAllCuisines(response.data);
    }

    async function loadHomeImages() {
        if (props?.data?.id) {
            let response = await axiosSecure.get(`/api/kiosk/files/carousel/home/${props?.data?.id}`, false);
            let images = response.data?.map((i: any) => ({ name: i?.filename })).filter(Boolean);
            if (images.length === 0) {

            } else {
                setCurrentHomeKioskImages(images)
            }
        }
    }

    async function loadMenuImages() {
        if (props?.data?.id) {
            let response = await axiosSecure.get(`/api/kiosk/files/carousel/menu/${props?.data?.id}`, false);
            let images = response.data?.map((i: any) => ({ name: i?.filename })).filter(Boolean);
            if (images.length === 0) {

            } else {
                setCurrentMenuKioskImages(images)
            }
        }
    }

    useEffect(() => {
        apiCall();
    }, [])

    useEffect(() => {
        loadHomeImages();
        loadMenuImages();
    }, [])

    useEffect(() => {

        setName(props?.data?.name || "");
        setDescription(props?.data?.description || "");
        setEmail(props?.data?.email || "");
        setPhone(props?.data?.phone || "");
        setStreet(props?.data?.street || "");
        setZipCode(props?.data?.zipCode || "");
        setCity(props?.data?.city || "");

        let country = countries.find(c => c.code === props?.data?.state)
        setState(country || null);

        setIsPopular(props?.data?.isPopular || false);
        setCuisines(props?.data?.cuisines || []);
        setTaxRates(props?.data?.taxRates?.map(t => `${t.taxRate}`)?.join(",") || "10,20");
        setKioskColor(props?.data?.kioskColor || "#D92121");
        
        setFirstName("");
        setLastName("");
        setLogin("");

        setFileToUpload(null);
        setLogoFileToUpload(null);
        setThumbnail(`${MINIO_URL}/restaurants/${props?.data?.id}`);
        setLogo(`${MINIO_URL}/logo/${props?.data?.id}`);

    }, [props?.data])

    useEffect(() => {

        props?.onChangeData({
            isValid: validateForm(),
            restaurant: {
                id: props?.data?.id,
                name,
                description,
                email,
                phone,
                street,
                zipCode,
                isPopular,
                city,
                kioskColor: kioskColor,
                state: state?.code,
                cuisines,
                taxRates: taxRates?.split(",")?.filter(isNumber)?.map(t => t.trim())?.filter(Boolean)?.map(t => ({
                    id: newGuid(),
                    taxRate: +t
                })),
                image: fileToUpload ? fileToUpload : null,
                homeKioskImages: homeKioskImages ? homeKioskImages : null,
                menuKioskImages: menuKioskImages ? menuKioskImages : null,
                logo: logoFileToUpload ? logoFileToUpload : null,
                clearHomeKioskImages: clearHomeKioskImages,
                clearMenuKioskImages: clearMenuKioskImages
            },
            user: {
                id: props?.data?.id,
                login: ownerLogin,
                email: ownerEmail,
                firstName: ownerFirstName,
                lastName: ownerLastName,
                phone: ownerPhone,
                street: ownerStreet,
                zipCode: ownerZipCode,
                city: ownerCity,
                state: ownerState?.code
            }
        });

    }, [name, description, email, phone, street, zipCode,
        isPopular, city, state, ownerLogin, ownerEmail,
        ownerFirstName, ownerLastName, ownerPhone, ownerStreet,
        ownerZipCode, ownerCity, ownerState,
        fileToUpload, cuisines, kioskColor, taxRates, logoFileToUpload, homeKioskImages,
        menuKioskImages, clearHomeKioskImages, clearMenuKioskImages
    ])


    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!email || !isValidEmail(email)) {
            setEmailError("Invalid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (props?.action === 'CREATE') {

            if (props?.action === 'CREATE') {
                if (!ownerLogin) {
                    setOwnerLoginError("* Required");
                    isValid = false;
                } else {
                    setOwnerLoginError("");
                }

                if (!ownerFirstName) {
                    setOwnerFirstNameError("* Required");
                    isValid = false;
                } else {
                    setOwnerFirstNameError("");
                }

                if (!ownerLastName) {
                    setOwnerLastNameError("* Required");
                    isValid = false;
                } else {
                    setOwnerLastNameError("");
                }

                if (!ownerEmail || !isValidEmail(ownerEmail)) {
                    setOwnerEmailError("Invalid email address");
                    isValid = false;
                } else {
                    setOwnerEmailError("");
                }

                if (!ownerPhone) {
                    setOwnerPhoneError("* Required");
                    isValid = false;
                } else {
                    setOwnerPhoneError("");
                }

                if (!ownerStreet) {
                    setOwnerStreetError("* Required");
                    isValid = false;
                } else {
                    setOwnerStreetError("");
                }

                if (!ownerZipCode) {
                    setOwnerZipCodeError("* Required");
                    isValid = false;
                } else {
                    setOwnerZipCodeError("");
                }

                if (!ownerCity) {
                    setOwnerCityError("* Required");
                    isValid = false;
                } else {
                    setOwnerCityError("");
                }

                if (!ownerState) {
                    setOwnerStateError("* Required");
                    isValid = false;
                } else {
                    setOwnerStateError("");
                }
            }
        }

        if (!phone) {
            setPhoneError("* Required");
            isValid = false;
        } else {
            setPhoneError("");
        }

        if (!taxRates) {
            setTaxRatesError("* Required");
            isValid = false;
        } else {
            setTaxRatesError("");
        }

        if (!street) {
            setStreetError("* Required");
            isValid = false;
        } else {
            setStreetError("");
        }

        if (!zipCode) {
            setZipCodeError("* Required");
            isValid = false;
        } else {
            setZipCodeError("");
        }

        if (!city) {
            setCityError("* Required");
            isValid = false;
        } else {
            setCityError("");
        }

        if (!state) {
            setStateError("* Required");
            isValid = false;
        } else {
            setStateError("");
        }

        return isValid;
    };

    const isValidEmail = (email: string) => {
        // Implement your email validation logic here
        return /\S+@\S+\.\S+/.test(email);
    };

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    function handleTaxRateChange(event: any) {
        setTaxRates(event.target.value);
    }

    function handleIsPopularChange(event: any) {
        setIsPopular(event.target.checked);
    }

    function handlePhoneChange(event: any) {
        setPhone(event.target.value);
    }

    function handleStreetChange(event: any) {
        setStreet(event.target.value);
    }

    function handleZipCodeChange(event: any) {
        setZipCode(event.target.value);
    }

    function handleCityChange(event: any) {
        setCity(event.target.value);
    }

    function handleStateChange(event: any, newValue: string | null) {
        setState(newValue);
    }

    // MANAGER PROFILE

    function handleOwnerLoginChange(event: any) {
        setOwnerLogin(event.target.value);
    }

    function handleOwnerFirstNameChange(event: any) {
        setOwnerFirstName(event.target.value);
    }

    function handleOwnerLastNameChange(event: any) {
        setOwnerLastName(event.target.value);
    }

    function handleOwnerEmailChange(event: any) {
        setOwnerEmail(event.target.value);
    }

    function handleOwnerPhoneChange(event: any) {
        setOwnerPhone(event.target.value);
    }

    function handleOwnerStreetChange(event: any) {
        setOwnerStreet(event.target.value);
    }

    function handleOwnerZipCodeChange(event: any) {
        setOwnerZipCode(event.target.value);
    }

    function handleOwnerCityChange(event: any) {
        setOwnerCity(event.target.value);
    }

    function handleOwnerStateChange(event: any, newValue: string | null) {
        setOwnerState(newValue);
    }

    function handleFileChange(fileList: File[]) {
        setFileToUpload(fileList[0]);
        const objectUrl = URL.createObjectURL(fileList[0]);
        setThumbnail(objectUrl);
        // setPreview(objectUrl)

        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };

    function handleRemoveRestaurantThumbnailClick(event: any) {
        event.stopPropagation();
        setFileToUpload(null);
        setThumbnail('');
    }

    function handleDeleteRestaurant() {
        props?.onDelete(props?.data);
    }

    function handleTabChange(event: React.SyntheticEvent, newValue: string) {
        setSelectedTab(newValue);
    }

    return (
        <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                    <Tab label="Info" value="1" />
                    <Tab label="Kiosk" value="2" />
                </TabList>
            </Box>
            <TabPanel value="1">
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.name')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={name}
                                onChange={handleNameChange}
                                error={!!nameError}
                                helperText={nameError}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.description')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                multiline
                                rows={4}
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.image')}</strong></Typography>
                            <FileUploading value={fileToUpload != null ? [fileToUpload] : []} onChange={handleFileChange}
                                acceptType={['jpg', 'jpeg', 'png']} maxFileSize={2097152}>
                                {({
                                    fileList,
                                    errors,
                                    isDragging,
                                    onFileUpload,
                                    onFileRemoveAll,
                                    onFileUpdate,
                                    onFileRemove,
                                    dragProps,
                                }) => {
                                    return (
                                        <>
                                            <Box
                                                id="btn-upload"
                                                onClick={onFileUpload}
                                                {...dragProps}
                                                sx={styles.fileUploadContainer}
                                            >
                                                {errors && errors.maxNumber && (
                                                    <span>Number of selected files exceed maxNumber</span>
                                                )}

                                                <>
                                                    <Stack
                                                        spacing={2}
                                                        direction="column"
                                                        justifyContent="space-between"
                                                        sx={styles.fileUploadLabelContainer}
                                                    >
                                                        <Typography noWrap
                                                            sx={styles.fileUploadLabel}
                                                        >
                                                            {t('containers.editors.restaurant.labels.chooseImage')}
                                                        </Typography>

                                                        {fileToUpload != null && (
                                                            <Stack
                                                                spacing={2}
                                                                direction="row"
                                                                justifyContent="space-between"
                                                            >
                                                                <Typography
                                                                    sx={styles.fileName}
                                                                >
                                                                    {fileToUpload?.name}

                                                                </Typography>

                                                                <DeleteIcon
                                                                    onClick={(e) => handleRemoveRestaurantThumbnailClick(e)} />
                                                            </Stack>)}
                                                    </Stack>
                                                    <img
                                                        style={{
                                                            filter: "brightness(70%)",
                                                            borderRadius: "5px",
                                                            height: "100%",
                                                            width: "100%",
                                                            objectFit: "cover"
                                                        }}
                                                        src={thumbnail}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = require("../../../../src/assets/restaurant_placeholder.png");
                                                        }}
                                                    />

                                                </>

                                            </Box>
                                        </>
                                    );
                                }}
                            </FileUploading>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.cuisine')}</strong></Typography>
                            <Autocomplete
                                multiple
                                id="cuisines"
                                options={allCuisines}
                                getOptionLabel={(option) => option?.name}
                                filterSelectedOptions
                                value={cuisines}
                                isOptionEqualToValue={(option: Cuisine, value: Cuisine) => option.id === value.id}
                                onChange={(event: any, newValue: any) => {
                                    setCuisines(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('containers.editors.restaurant.labels.cuisine')}
                                        placeholder={t('containers.editors.restaurant.labels.cuisine')}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.taxRate')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={taxRates}
                                onChange={handleTaxRateChange}
                                error={!!taxRatesError}
                                helperText={taxRatesError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.email')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={email}
                                onChange={handleEmailChange}
                                error={!!emailError}
                                helperText={emailError}
                            />
                        </Grid>

                        {props?.showIsPopular && <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.isPopular')}</strong></Typography>
                            <Checkbox onChange={handleIsPopularChange} checked={isPopular} />
                        </Grid>}

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.phoneNumber')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={phone}
                                onChange={handlePhoneChange}
                                error={!!phoneError}
                                helperText={phoneError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.street')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={street}
                                onChange={handleStreetChange}
                                error={!!streetError}
                                helperText={streetError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.zipCode')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={zipCode}
                                onChange={handleZipCodeChange}
                                error={!!zipCodeError}
                                helperText={zipCodeError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.city')}</strong></Typography>
                            <StyledTextField
                                variant="outlined"
                                value={city}
                                onChange={handleCityChange}
                                error={!!cityError}
                                helperText={cityError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography><strong>{t('containers.editors.restaurant.labels.country')}</strong></Typography>
                            <Autocomplete
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                options={countries}
                                value={state}
                                onChange={handleStateChange}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            alt=""
                                        />
                                        {option.label} ({option.code})
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!stateError}
                                        helperText={stateError}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        {/* TODO */}
                        {props?.action === 'CREATE' && <>
                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.userName')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerLogin}
                                    onChange={handleOwnerLoginChange}
                                    error={!!ownerLoginError}
                                    helperText={ownerLoginError}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>{t('containers.editors.profile.labels.firstName')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerFirstName}
                                    onChange={handleOwnerFirstNameChange}
                                    error={!!ownerFirstNameError}
                                    helperText={ownerFirstNameError}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography><strong>{t('containers.editors.profile.labels.lastName')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerLastName}
                                    onChange={handleOwnerLastNameChange}
                                    error={!!ownerLastNameError}
                                    helperText={ownerLastNameError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.email')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerEmail}
                                    onChange={handleOwnerEmailChange}
                                    error={!!ownerEmailError}
                                    helperText={ownerEmailError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.phoneNumber')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerPhone}
                                    onChange={handleOwnerPhoneChange}
                                    error={!!ownerPhoneError}
                                    helperText={ownerPhoneError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.street')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerStreet}
                                    onChange={handleOwnerStreetChange}
                                    error={!!ownerStreetError}
                                    helperText={ownerStreetError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.zipCode')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerZipCode}
                                    onChange={handleOwnerZipCodeChange}
                                    error={!!ownerZipCodeError}
                                    helperText={ownerZipCodeError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.city')}</strong></Typography>
                                <StyledTextField
                                    variant="outlined"
                                    value={ownerCity}
                                    onChange={handleOwnerCityChange}
                                    error={!!ownerCityError}
                                    helperText={ownerCityError}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography><strong>{t('containers.editors.profile.labels.country')}</strong></Typography>
                                <Autocomplete
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    options={countries}
                                    value={ownerState}
                                    onChange={handleOwnerStateChange}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label} ({option.code})
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!ownerStateError}
                                            helperText={ownerStateError}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>}
                    </Grid>

                    {props?.action === 'UPDATE' && props?.showDelete && <>
                        <Divider />

                        <Button
                            color="black"
                            variant="text"
                            onClick={handleDeleteRestaurant}
                        >
                            {t('containers.editors.restaurant.actions.delete')}
                        </Button>
                    </>}
                </Stack>
            </TabPanel>

            <TabPanel value="2">

                <Grid item xs={4}>

                    <Stack direction="column" spacing={2}>

                        {/* <Typography><strong>{t('Logo')}</strong></Typography> */}

                        <Alert severity="info"
                            sx={{
                                marginTop: '10px',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            <AlertTitle><strong>{t('Kiosk Color')}</strong><br /></AlertTitle>
                            {t('Your Kiosk interface color.')}

                        </Alert>

                        <MuiColorInput format="hex" value={kioskColor} onChange={setKioskColor}/>

                        <Alert severity="info"
                            sx={{
                                marginTop: '10px',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            <AlertTitle><strong>{t('Kiosk Logo')}</strong><br /></AlertTitle>
                            {t('Your Kiosk Logo.')}

                        </Alert>

                        <FileUpload
                            sourceUrl={logo}
                            defaultSourceUrl={"../../../../src/assets/restaurant_placeholder.png"}
                            label={t('containers.editors.restaurant.labels.chooseImage')}
                            onChangeData={(e: any) => {
                                setLogoFileToUpload(e.file);
                            }}
                        />

                        {/* <Typography><strong>{t('Home')}</strong></Typography> */}

                        <Alert severity="info"
                            sx={{
                                marginTop: '10px',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            <AlertTitle><strong>{t('Home Screen')}</strong><br /></AlertTitle>
                            {t('Media that will be displayed on the home screen of the kiosk. Up to 5 files.')}

                        </Alert>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <img
                                style={{
                                    height: 200,
                                    objectFit: "cover",
                                    background: "linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)"
                                }}
                                src={require("../../../../src/assets/kiosk/carousel_home.png")}
                            />

                            {/* <div style={{ height: "100%" }}> */}
                            <MultiFileUpload
                                placeholderFiles={currentHomeKioskImages}
                                maxNumber={5}
                                label={t('containers.editors.restaurant.labels.chooseImage')}
                                onChangeData={(e: any) => {
                                    setHomeKioskImages(e.files);
                                }}
                            />

                            {/* </div> */}
                        </Stack>

                        {/* <Typography><strong>{t('Small')}</strong></Typography> */}

                        <Alert severity="info"
                            sx={{
                                marginTop: '10px',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            <AlertTitle><strong>{t('Kiosk Top')}</strong><br /></AlertTitle>
                            {t('Media that will be displayed on the Top of the Kiosk screens. Up to 5 files.')}

                        </Alert>

                        <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={2}>
                            <img
                                style={{
                                    width: 200,
                                    objectFit: "cover"
                                }}
                                src={require("../../../../src/assets/kiosk/carousel_menu.png")}
                            />

                            <div style={{ width: "100%" }}>
                                <MultiFileUpload
                                    placeholderFiles={currentMenuKioskImages}
                                    maxNumber={5}
                                    label={t('containers.editors.restaurant.labels.chooseImage')}
                                    onChangeData={(e: any) => {
                                        setMenuKioskImages(e.files);
                                    }}
                                />
                            </div>
                        </Stack>
                    </Stack>

                </Grid>
            </TabPanel>

        </TabContext>

    );
}


const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '125px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100px'
    }
}