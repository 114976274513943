import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, AlertColor, Slide, SlideProps, useTheme } from '@mui/material'

export interface NotificationProps {
  message: string,
  severity: string,
  autoHideDuration: number
}

function Transition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export default function Notification({ message, severity, autoHideDuration }: NotificationProps): JSX.Element | null {
  const [notiOpen, setNotiOpen] = useState(true)
  const theme = useTheme();

  if (!message) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Transition}
      open={notiOpen}
      onClose={() => setNotiOpen(false)}
    >
      <Alert onClose={() => setNotiOpen(false)}
        severity={
          severity as AlertColor
        }
        sx={{
          width: '100%',
          color: theme.palette.primary.main
        }}>
        {message}
      </Alert>
    </Snackbar>
  )
}