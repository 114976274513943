import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import useLoading from '../../../hooks/useLoading';


export default function Loading() {
  const { showLoading } = useLoading();

  return (
    <Modal
      open={showLoading}
    >
      <Box sx={style}>
        <CircularProgress color="primary" />
      </Box>
    </Modal>
  );
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  bgcolor: 'transparent'
};