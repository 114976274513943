import { Box, Theme, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-multi-lang";

export default function Unauthorized() {

    const theme = useTheme();
    const styles = makeStyles(theme);
    const t = useTranslation();

    return (

        <Box sx={styles.container}>
            <Typography sx={styles.title}>
                {t('pages.unauthorized.title')}
            </Typography>
        </Box>
    );
}

const makeStyles = (theme: Theme) => {
    return {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh"
        },
        title: {
            fontSize: {
                lg: "xxx-large",
                md: "xx-large",
                sm: "x-large"
            },
            fontWeight: "bold",
            color: theme.palette.text.primary
        }
    }
}