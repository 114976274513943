import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Category } from '../../../../shared/interfaces/category.interface';
import { ExtraCategoryExtraMenuitem } from '../../../../shared/interfaces/extra-category-extra-menuitem.interface';
import { Menuitem } from '../../../../shared/interfaces/menuitem.interface';
import { MenuItemExtraCategory } from '../../../../shared/interfaces/menuitem-extra-category.interface';
import { ExtraCategory } from '../../../../shared/interfaces/extra-category.interface';


const initialState = {
    categories: [] as Category[]
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        reset: () => initialState,
        addCategories: (state, action: PayloadAction<Category[]>) => {
            if (state.categories.length > 0) {
                let newCategories = action.payload.filter(c => state.categories.map(i => i.id).indexOf(c.id) === -1);
                if (newCategories && newCategories.length > 0) {
                    state.categories = [...state.categories, ...newCategories];
                }
            } else {
                state.categories = action.payload;
            }
        },
        addCategory: (state, action: PayloadAction<Category>) => {
            state.categories.push(action.payload);
        },
        updateCategory: (state, action: PayloadAction<Category>) => {
            const category = state.categories.find(c => c.id === action.payload.id) as Category;
            Object.assign(category, action.payload);
        },
        updateCategories: (state, action: PayloadAction<Partial<Category>[]>) => {
            for (let change of action.payload) {
                const category = state.categories.find(c => c.id === change.id) as Category;
                Object.assign(category, change);
            }
        },
        addMenuitem: (state, action: PayloadAction<Menuitem>) => {
            const category = state.categories.find(c => c.id === action.payload.categoryId) as Category;
            category.menuitems.push(action.payload);
        },
        updateMenuitem: (state, action: PayloadAction<Menuitem>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    if (m.id === action.payload.id) {
                        Object.assign(m, action.payload);
                    }
                })
            });
        },
        upsertMenuitem: (state, action: PayloadAction<Menuitem>) => {
            const category = state.categories.find(c => c.id === action.payload.categoryId) as Category;
            if (!category?.menuitems?.find(m => m.id === action.payload.id)) {
                category.menuitems.push(action.payload);
            } else {
                state.categories.forEach(c => {
                    c.menuitems.forEach(m => {
                        if (m.id === action.payload.id) {
                            Object.assign(m, action.payload);
                        }
                    })
                });
            }
        },
        addExtraMenuitem: (state, action: PayloadAction<{ extraCategoryId: string, extraCategoryExtraMenuitem: ExtraCategoryExtraMenuitem }>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    m.menuitemExtraCategories.forEach(ec => {
                        if (ec.extraCategoryId === action.payload.extraCategoryId) {
                            ec.extraCategory.extraCategoryExtraMenuitems.push(action.payload.extraCategoryExtraMenuitem);
                        }
                    })
                })
            });
        },
        removeExtraMenuitem: (state, action: PayloadAction<ExtraCategoryExtraMenuitem>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    m.menuitemExtraCategories.forEach(ec => {
                        if (ec.extraCategoryId === action.payload.extraCategoryId) {
                            ec.extraCategory.extraCategoryExtraMenuitems = ec.extraCategory.extraCategoryExtraMenuitems.filter(em => em.id !== action.payload.id);
                        }
                    })
                })
            });
        },
        updateExtraMenuitem: (state, action: PayloadAction<ExtraCategoryExtraMenuitem>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    m.menuitemExtraCategories.forEach(ec => {
                        if (ec.extraCategoryId === action.payload.extraCategoryId) {
                            ec.extraCategory.extraCategoryExtraMenuitems.forEach(em => {
                                if (em.id === action.payload.id) {
                                    Object.assign(em, action.payload);
                                }
                            })
                            ec.extraCategory.extraCategoryExtraMenuitems.sort((a: ExtraCategoryExtraMenuitem, b: ExtraCategoryExtraMenuitem) => a.viewOrder - b.viewOrder);
                        }
                    })
                })
            });
        },
        updateExtraMenuitems: (state, action: PayloadAction<{ extraCategoryId: string, extraCategoryExtraMenuitems: Partial<ExtraCategoryExtraMenuitem>[] }>) => {
            for (let change of action.payload.extraCategoryExtraMenuitems) {
                state.categories.forEach(c => {
                    c.menuitems.forEach(m => {
                        m.menuitemExtraCategories.forEach(ec => {
                            if (ec.extraCategoryId === action.payload.extraCategoryId) {
                                ec.extraCategory.extraCategoryExtraMenuitems.forEach(em => {
                                    if (em.id === change.id) {
                                        Object.assign(em, change);
                                    }
                                })
                                ec.extraCategory.extraCategoryExtraMenuitems.sort((a: ExtraCategoryExtraMenuitem, b: ExtraCategoryExtraMenuitem) => a.viewOrder - b.viewOrder);
                            }
                        })
                    })
                });
            }
        },
        addExtraCategory: (
            state,
            action: PayloadAction<{
                extraCategory: ExtraCategory,
                menuItemExtraCategory: MenuItemExtraCategory
            }>) => {
            const category = state.categories.find(c => c?.menuitems?.map(i => i.id)?.indexOf(action.payload.menuItemExtraCategory.menuitemId) > -1) as Category;
            const menuitem = category.menuitems.find(c => c.id === action.payload.menuItemExtraCategory.menuitemId) as Menuitem;
            menuitem.menuitemExtraCategories.push(action.payload.menuItemExtraCategory);
        },
        updateExtraCategory: (state, action: PayloadAction<Partial<MenuItemExtraCategory>>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    if (m.id === action.payload.menuitemId) {
                        m.menuitemExtraCategories.forEach(ec => {
                            if (ec.id === action.payload.id) {
                                Object.assign(ec, action.payload);
                            }
                        });
                        m.menuitemExtraCategories.sort((a: MenuItemExtraCategory, b: MenuItemExtraCategory) => a.viewOrder - b.viewOrder);
                    }
                })
            });
        },
        updateExtraCategories: (state, action: PayloadAction<Partial<MenuItemExtraCategory>[]>) => {
            for (let change of action.payload) {
                state.categories.forEach(c => {
                    c.menuitems.forEach(m => {
                        if (m.id === change.menuitemId) {
                            m.menuitemExtraCategories.forEach(ec => {
                                if (ec.id === change.id) {
                                    Object.assign(ec, change);
                                }
                            });
                            m.menuitemExtraCategories.sort((a: MenuItemExtraCategory, b: MenuItemExtraCategory) => a.viewOrder - b.viewOrder);
                        }
                    })
                });
            }
        },
        deleteCategory: (state, action: PayloadAction<string>) => {
            state.categories = [...state.categories.filter(c => c.id !== action.payload)];
        },
        deleteCategories: (state, action: PayloadAction<string[]>) => {
            state.categories = [...state.categories.filter(c => action.payload.indexOf(c.id) === -1)];
        },
        deleteExtraCategory: (state, action: PayloadAction<string>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    m.menuitemExtraCategories = m.menuitemExtraCategories.filter(ec => ec.id !== action.payload);
                })
            });
        },
        deleteExtraMenuitem: (state, action: PayloadAction<string>) => {
            state.categories.forEach(c => {
                c.menuitems.forEach(m => {
                    m.menuitemExtraCategories.forEach(ec => {
                        ec.extraCategory.extraCategoryExtraMenuitems = ec.extraCategory.extraCategoryExtraMenuitems.filter(em => em.id !== action.payload);
                    })
                })
            });
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    reset,
    addCategories,
    addCategory,
    updateCategory,
    updateCategories,
    deleteCategory,
    deleteCategories,
    addMenuitem,
    updateMenuitem,
    upsertMenuitem,
} = categorySlice.actions

export default { categoryReducer: categorySlice.reducer }
