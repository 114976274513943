import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Festival } from '../../../../shared/interfaces/festival.interface';


const initialState = {
    festivals: [] as Festival[]
}

export const festivalSlice = createSlice({
    name: 'festival',
    initialState,
    reducers: {
        reset: () => initialState,
        addFestivals: (state, action: PayloadAction<Festival[]>) => {
            if (state.festivals.length > 0) {
                let newFestivals = action.payload.filter(c => state.festivals.map(i => i.id).indexOf(c.id) === -1);
                if (newFestivals && newFestivals.length > 0) {
                    state.festivals = [...state.festivals, ...newFestivals];
                }
            } else {
                state.festivals = action.payload;
            }
        },
        addFestival: (state, action: PayloadAction<Festival>) => {
            state.festivals.push(action.payload);
        },
        updateFestival: (state, action: PayloadAction<Festival>) => {
            const festival = state.festivals.find(c => c.id === action.payload.id) as Festival;
            Object.assign(festival, action.payload);
        },
        upsertFestival: (state, action: PayloadAction<Festival>) => {
            const festival = state.festivals.find(c => c.id === action.payload.id) as Festival;
            if (!festival) {
                state.festivals.push(action.payload);
            } else {
                state.festivals.forEach(f => {
                    if (f.id === action.payload.id) {
                        Object.assign(f, action.payload);
                    }
                });
            }
        },
        updateFestivals: (state, action: PayloadAction<Partial<Festival>[]>) => {
            for (let change of action.payload) {
                const festival = state.festivals.find(c => c.id === change.id) as Festival;
                Object.assign(festival, change);
            }
        },
        deleteFestival: (state, action: PayloadAction<string>) => {
            state.festivals = [...state.festivals.filter(c => c.id !== action.payload)];
        },
        deleteFestivals: (state, action: PayloadAction<string[]>) => {
            state.festivals = [...state.festivals.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    reset,
    addFestivals,
    addFestival,
    updateFestival,
    upsertFestival,
    updateFestivals,
    deleteFestival,
    deleteFestivals,
} = festivalSlice.actions

export default { festivalReducer: festivalSlice.reducer }
