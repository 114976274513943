import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface MultiSelectProps {
    items: any[];
    value?: string[];
    idProperty?: string;
    labelProperty?: string;
    placeholder?: string;
    style?: any;
    onChange?: (event: any) => void;
}

export default function MultiSelect({
    items,
    idProperty = "id",
    labelProperty = "name",
    placeholder = "Placeholder",
    value = [],
    style = {},
    onChange
}: MultiSelectProps) {
    const [checkedItems, setCheckedItems] = useState<string[]>(value);

    const handleChange = (event: SelectChangeEvent<typeof checkedItems>) => {
        const {
            target: { value },
        } = event;

        setCheckedItems(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        onChange?.(value);
    };

    return (
        <div>
            <FormControl sx={{ ...style }}>
                <InputLabel>{placeholder}</InputLabel>
                <Select
                    labelId="multiselect-label"
                    id="multiselect"
                    multiple
                    value={checkedItems}
                    onChange={handleChange}
                    input={<OutlinedInput label={placeholder} />}
                    renderValue={(selected) => {
                        if (checkedItems.length === 0) {
                            <em>{placeholder}</em>
                        }

                        return items?.filter(i => checkedItems.indexOf(i[idProperty]) > -1)?.map(i => i[labelProperty]).join(', ');
                    }}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                    {items?.map((item) => (
                        <MenuItem key={item[idProperty]} value={item[idProperty]}>
                            <Checkbox checked={checkedItems.indexOf(item[idProperty]) > -1} />
                            <ListItemText primary={item[labelProperty]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}