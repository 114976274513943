import { useEffect, useState } from "react";
import OrderItem from "../../app/components/OrderItem/OrderItem";
import useAxiosSecure from "../../hooks/useAxiosSecure";
import StackGrid from "react-stack-grid";
import { RootState } from "../../app/core/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addOrders, selectOrdersForBoardByRestaurantId, selectOrdersForCurrentRestaurant, updateOrder } from "../../app/core/store/features/order/orderSlice";
import { OrderStateEnum } from "../../app/shared/interfaces/order-state.interface";
import { Order } from "../../app/shared/interfaces/order.interface";
import { useTranslation } from "react-multi-lang";
import AlertDialog from "../../app/dialogs/AlertDialog/AlertDialog";
import { dayjs } from "../../app/core/utilities";
import axios from "../../app/core/api/axios";
import { Box, Divider, Grid, List, ListItem, Stack, Theme, Typography, useTheme } from "@mui/material";


export default function OrdersBoard() {

    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

    const theme = useTheme();
    const styles = makeStyles(theme);
    const orderSearch = useSelector((state: RootState) => state.ui.orderSearch);
    const axiosSecure = useAxiosSecure();
    const orderDateRange = useSelector((state: RootState) => state.ui.orderDateRange);
    const orderStateIds = [OrderStateEnum.IsCreated, OrderStateEnum.IsPreparing, OrderStateEnum.IsDone];



    const orders = useSelector(selectOrdersForCurrentRestaurant)


    // .filter((o) => {
    //     const dateToCheckTimestamp = dayjs((o as Order).createdAt).valueOf();

    //     let [from, to] = getDateRange("today");

    //     const startDateTimestamp = dayjs(from).startOf('day').valueOf()
    //     const endDateTimestamp = dayjs(to).endOf('day').valueOf()

    //     return dateToCheckTimestamp >= startDateTimestamp && dateToCheckTimestamp <= endDateTimestamp
    //         && orderStateIds.indexOf(o?.orderStateId as OrderStateEnum) > -1;
    // });

    const dispatch = useDispatch();
    const t = useTranslation();

    function getDateRange(dateRange: string) {
        switch (dateRange) {
            case "today": {
                var start = new Date();
                start.setUTCHours(0, 0, 0, 0);

                var end = new Date();
                end.setUTCHours(23, 59, 59, 999);

                let from = start.toISOString()
                let to = end.toISOString()
                return [from, to];
            }
            case "yesterday": {
                const base = dayjs()
                    .utc()
                    .subtract(1, "d");
                const start = base.startOf("day");
                const end = base.endOf("day");
                return [start.toISOString(), end.toISOString()];
            }
            case "this-week": {
                const base = dayjs().utc();
                const start = base.startOf("week");
                const end = base.endOf("week");
                return [start.toISOString(), end.toISOString()];
            }
            case "this-month": {
                const base = dayjs().utc();
                const start = base.startOf("month");
                const end = base.endOf("month");
                return [start.toISOString(), end.toISOString()];
            }
            default: {
                var start = new Date();
                start.setUTCHours(0, 0, 0, 0);

                var end = new Date();
                end.setUTCHours(23, 59, 59, 999);

                let from = start.toISOString()
                let to = end.toISOString()
                return [from, to];
            }
        }
    }

    useEffect(() => {
        async function apiCall() {

            let [from, to] = getDateRange("today");


            let response = await axiosSecure.get(`/api/order?restaurantId=${auth?.user?.restaurantId}&dateRangeFrom=${from}&dateRangeTo=${to}&${orderStateIds.map((n, index) => `orderStateIds=${n}`).join('&')}`, true);

            dispatch(addOrders(response.data?.map((order: Order) => ({
                ...order,
                state: order?.orderStateId === OrderStateEnum.IsDone ? t('orderState.isDone') : (
                    order?.orderStateId === OrderStateEnum.IsPreparing ? t('orderState.isPreparing') : (order?.orderStateId === OrderStateEnum.IsCanceled ? t('orderState.isCanceled') : t('orderState.isCreated'))
                )
            }))));
        }

        apiCall();
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWindowHeight(window.innerHeight);
    }

    return (

        <Grid container direction="row" spacing={2}
            sx={styles.container}
        >
            {/* <img
                style={{
                    // width: "500px",
                    height: '10%',
                    position: 'absolute',
                    opacity: 0.4,
                    right: 20,
                    bottom: 20,
                    zIndex: 1
                }}
                src={require("../../../src/assets/logo.png")}
            /> */}

            <Grid item xs
                sx={{
                    ...styles.column,
                    // height: windowHeight - 24 - 24 - 20 - 20
                }}>
                <Typography variant="h1" sx={styles.doneHeader}>
                    {t('pages.orderBoard.labels.done')}
                </Typography>

                {/* <Divider sx={styles.divider} /> */}

                <List sx={styles.list}>
                    {orders.filter(o => o?.orderStateId === OrderStateEnum.IsDone).map((order, index) => (
                        <Box key={order?.id}>
                            <Typography
                                //variant={"h1"}
                                sx={{
                                    ...styles.doneOrderNumber,
                                    fontSize: index === 0 ? '8rem' : '6rem'
                                }}
                            >
                                {order?.orderNumber}
                            </Typography>
                        </Box>
                    ))}
                </List>
            </Grid>

            <Grid item xs sx={{
                ...styles.column,
                // height: windowHeight - 24 - 24 - 20 - 20
            }}>
                <Typography variant="h1" sx={styles.header}>
                    {t('pages.orderBoard.labels.preparing')}
                </Typography>

                {/* <Divider sx={styles.divider} /> */}

                <List sx={styles.list}>
                    {orders.filter(o => o?.orderStateId === OrderStateEnum.IsPreparing).map((order, index) => (
                        <Box key={order?.id}>
                            <Typography
                                variant="h1"
                                sx={styles.orderNumber}
                            >
                                {order?.orderNumber}
                            </Typography>
                        </Box>
                    ))}
                </List>
            </Grid>


            <Grid item xs sx={{
                ...styles.column,
                // height: windowHeight - 24 - 24 - 20 - 20
            }}>
                <Typography variant="h1" sx={styles.header}>
                    {t('pages.orderBoard.labels.created')}
                </Typography>

                {/* <Divider sx={styles.divider} /> */}

                <List sx={styles.list}>
                    {orders.filter(o => o?.orderStateId === OrderStateEnum.IsCreated).map((order, index) => (
                        <Box key={order?.id}>
                            <Typography
                                variant="h2"
                                sx={styles.orderNumber}
                            >
                                {order?.orderNumber}
                            </Typography>
                        </Box>
                    ))}
                </List>
            </Grid>

        </Grid>
    );
}


const makeStyles = (theme: Theme) => {
    return {
        container: {
            position: 'relative',
            height: window.innerHeight,
            overflow: 'hidden'
        },
        column: {
            height: window.innerHeight - 24 - 24 - 20 - 20,
            textAlign: "center"
        },
        divider: {
            width: '100%',
            marginY: '5px'
        },
        list: {
            marginTop: '10px',
            overflow: 'auto',
            height: '100%'
        },
        header: {
            fontWeight: 'bold'
        },
        doneHeader: {
            background: theme.palette.ketchup.main,
            color: theme.palette.white.main,
            fontWeight: 'bold'
        },
        order: {
            padding: 5
        },
        doneOrderNumber: {
            color: theme.palette.ketchup.main,
            fontWeight: '900'
        },
        orderNumber: {
            color: theme.palette.black.main,
            fontWeight: 'bold'
        }
    }
}