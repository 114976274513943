import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Button, Chip, Divider, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, MenuItem, Select, Stack, styled, TextField, useTheme } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploading from 'react-files-uploading';
import { Menuitem } from '../../shared/interfaces/menuitem.interface';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { ExtraCategory } from '../../shared/interfaces/extra-category.interface';
import { useTranslation } from 'react-multi-lang';
import { MINIO_URL } from '../../core/api/axios';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { Artist } from '../../shared/interfaces/artist.interface';



export interface ArtistEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: Artist;
    onChangeData: (data: any) => void;
    onDelete: (e: Artist) => void;
}

export default function ArtistEditor(props: ArtistEditorProps) {

    const theme = useTheme();

    const [name, setName] = useState(props?.data?.name || "");
    const [description, setDescription] = useState(props?.data?.description || "");
    const [genre, setGenre] = useState(props?.data?.genre || "");
    const [previewUrl, setPreviewUrl] = useState(props?.data?.previewUrl || "");
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [thumbnail, setThumbnail] = useState<string>("../../../../src/assets/festival_placeholder.png");

    const [instagramLink, setInstagramLink] = useState("");
    const [facebookLink, setFacebookLink] = useState("");
    const [xLink, setXLink] = useState("");
    const [appleMusicLink, setAppleMusicLink] = useState("");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [spotifyLink, setSpotifyLink] = useState("");

    const [nameError, setNameError] = useState("");
    const [genreError, setGenreError] = useState("");

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();


    // useEffect(() => {
    //     async function apiCall() {

    //         let filter = "";

    //         if (auth.user) {
    //             filter += `restaurantId=${auth.user?.restaurantId}&`;
    //         }

    //         let response = await axiosSecure.get(`/api/extra_category?${filter}`, true);

    //         setExtraCategories(response.data)

    //     }

    //     apiCall();
    // }, [])


    useEffect(() => {

        setName(props?.data?.name || "");
        setDescription(props?.data?.description || "");
        setGenre(props?.data?.genre || "");
        setPreviewUrl(props?.data?.previewUrl || "");
        setInstagramLink(props?.data?.instagramLink || "");
        setFacebookLink(props?.data?.facebookLink || "");
        setXLink(props?.data?.xLink || "");
        setAppleMusicLink(props?.data?.appleMusicLink || "");
        setYoutubeLink(props?.data?.youtubeLink || "");
        setSpotifyLink(props?.data?.spotifyLink || "");

        setFileToUpload(null);
        setThumbnail(`${MINIO_URL}/artists/${props?.data?.id}`);

    }, [props?.data])


    useEffect(() => {

        props?.onChangeData({
            isValid: validateForm(),
            id: props?.data?.id,
            name,
            description,
            genre,
            previewUrl,
            instagramLink,
            facebookLink,
            xLink,
            appleMusicLink,
            youtubeLink,
            spotifyLink,

            image: fileToUpload ? fileToUpload : null
        });

    }, [name, description, genre, previewUrl, fileToUpload, instagramLink, facebookLink,
        xLink, appleMusicLink, youtubeLink, spotifyLink])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        if (!genre) {
            setGenreError("* Required");
            isValid = false;
        } else {
            setGenreError("");
        }

        return isValid;
    };

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    function handleGenreChange(event: any) {
        setGenre(event.target.value);
    }

    function handlePreviewUrlChange(event: any) {
        setPreviewUrl(event.target.value);
    }

    function handleInstagramLinkChange(event: any) {
        setInstagramLink(event.target.value);
    }

    function handleFacebookLinkChange(event: any) {
        setFacebookLink(event.target.value);
    }

    function handleXLinkChange(event: any) {
        setXLink(event.target.value);
    }

    function handleAppleMusicLinkChange(event: any) {
        setAppleMusicLink(event.target.value);
    }

    function handleYoutubeLinkChange(event: any) {
        setYoutubeLink(event.target.value);
    }

    function handleSpotifyLinkChange(event: any) {
        setSpotifyLink(event.target.value);
    }

    function handleFileChange(fileList: File[]) {
        setFileToUpload(fileList[0]);
        const objectUrl = URL.createObjectURL(fileList[0]);
        setThumbnail(objectUrl);
        // setPreview(objectUrl)

        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };

    function handleRemoveArtistThumbnailClick(event: any) {
        event.stopPropagation();
        setFileToUpload(null);
        setThumbnail('');
    }

    function handleDeleteArtist() {
        props?.onDelete(props?.data);
    }

    return (
        <Stack spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.name')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={name}
                        onChange={handleNameChange}
                        error={!!nameError}
                        helperText={nameError}
                    />
                </Grid>

                <Grid item xs={8}>
                    <Typography><strong>{t('containers.editors.artist.labels.description')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        multiline
                        rows={4}
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Typography><strong>{t('containers.editors.artist.labels.image')}</strong></Typography>
                    <FileUploading value={fileToUpload != null ? [fileToUpload] : []} onChange={handleFileChange}
                        acceptType={['jpg', 'jpeg', 'png']} maxFileSize={2097152}>
                        {({
                            fileList,
                            errors,
                            isDragging,
                            onFileUpload,
                            onFileRemoveAll,
                            onFileUpdate,
                            onFileRemove,
                            dragProps,
                        }) => {
                            return (
                                <>
                                    <Box
                                        id="btn-upload"
                                        onClick={onFileUpload}
                                        {...dragProps}
                                        sx={styles.fileUploadContainer}
                                    >
                                        {errors && errors.maxNumber && (
                                            <span>Number of selected files exceed maxNumber</span>
                                        )}

                                        <>
                                            <Stack
                                                spacing={2}
                                                direction="column"
                                                justifyContent="space-between"
                                                sx={styles.fileUploadLabelContainer}
                                            >
                                                <Typography noWrap sx={styles.fileUploadLabel}>
                                                    {t('containers.editors.artist.labels.chooseImage')}
                                                </Typography>

                                                {fileToUpload != null && (
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography sx={styles.fileName}>
                                                            {fileToUpload?.name}
                                                        </Typography>

                                                        <DeleteIcon
                                                            onClick={(e) => handleRemoveArtistThumbnailClick(e)} />
                                                    </Stack>)}
                                            </Stack>
                                            <img
                                                style={{
                                                    filter: "brightness(70%)",
                                                    borderRadius: "5px",
                                                    height: "100%",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                                src={thumbnail}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = require("../../../../src/assets/festival_placeholder.png");
                                                }}
                                            />

                                        </>

                                    </Box>
                                </>
                            );
                        }}
                    </FileUploading>
                </Grid>

                <Grid item xs={6}>
                    <Typography><strong>{t('containers.editors.artist.labels.genre')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={genre}
                        onChange={handleGenreChange}
                        error={!!genreError}
                        helperText={genreError}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography><strong>{t('containers.editors.artist.labels.previewUrl')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={previewUrl}
                        onChange={handlePreviewUrlChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.instagramLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={instagramLink}
                        onChange={handleInstagramLinkChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.facebookLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={facebookLink}
                        onChange={handleFacebookLinkChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.xLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={xLink}
                        onChange={handleXLinkChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.appleMusicLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={appleMusicLink}
                        onChange={handleAppleMusicLinkChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.youtubeLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={youtubeLink}
                        onChange={handleYoutubeLinkChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography><strong>{t('containers.editors.artist.labels.spotifyLink')}</strong></Typography>
                    <StyledTextField
                        variant="outlined"
                        value={spotifyLink}
                        onChange={handleSpotifyLinkChange}
                    />
                </Grid>

            </Grid>

            {props?.action === 'UPDATE' && <>
                <Divider />

                <Button
                    color="black"
                    variant="text"
                    onClick={handleDeleteArtist}
                >
                    {t('containers.editors.artist.actions.delete')}
                </Button>
            </>}
        </Stack>
    );
}


const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '125px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100px'
    }
}