import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Artist } from '../../../../shared/interfaces/artist.interface';


const initialState = {
    artists: [] as Artist[]
}

export const artistSlice = createSlice({
    name: 'artist',
    initialState,
    reducers: {
        reset: () => initialState,
        addArtists: (state, action: PayloadAction<Artist[]>) => {
            if (state.artists.length > 0) {
                let newArtists = action.payload.filter(c => state.artists.map(i => i.id).indexOf(c.id) === -1);
                if (newArtists && newArtists.length > 0) {
                    state.artists = [...state.artists, ...newArtists];
                }
            } else {
                state.artists = action.payload;
            }
        },
        addArtist: (state, action: PayloadAction<Artist>) => {
            state.artists.push(action.payload);
        },
        updateArtist: (state, action: PayloadAction<Artist>) => {
            const artist = state.artists.find(c => c.id === action.payload.id) as Artist;
            Object.assign(artist, action.payload);
        },
        upsertArtist: (state, action: PayloadAction<Artist>) => {
            const artist = state.artists.find(c => c.id === action.payload.id) as Artist;
            if (!artist) {
                state.artists.push(action.payload);
            } else {
                state.artists.forEach(f => {
                    if (f.id === action.payload.id) {
                        Object.assign(f, action.payload);
                    }
                });
            }
        },
        updateArtists: (state, action: PayloadAction<Partial<Artist>[]>) => {
            for (let change of action.payload) {
                const artist = state.artists.find(c => c.id === change.id) as Artist;
                Object.assign(artist, change);
            }
        },
        deleteArtist: (state, action: PayloadAction<string>) => {
            state.artists = [...state.artists.filter(c => c.id !== action.payload)];
        },
        deleteArtists: (state, action: PayloadAction<string[]>) => {
            state.artists = [...state.artists.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    reset,
    addArtists,
    addArtist,
    updateArtist,
    upsertArtist,
    updateArtists,
    deleteArtist,
    deleteArtists,
} = artistSlice.actions

export default { artistReducer: artistSlice.reducer }
