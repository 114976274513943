import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StackGrid from "react-stack-grid";
import { RootState } from "../../app/core/store/store";
import { useNavigate } from "react-router-dom";
import useAxiosSecure from "../../hooks/useAxiosSecure";
import { addRestaurants } from "../../app/core/store/features/restaurant/restaurantSlice";
import { addFestivals } from "../../app/core/store/features/festival/festivalSlice";
import RestaurantItem from "../../app/components/RestaurantItem/RestaurantItem";
import FestivalItem from "../../app/components/FestivalItem/FestivalItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography, useTheme } from "@mui/material";
import { setPanelContext } from "../../app/core/store/features/sidePanel/sidePanelSlice";
import { newGuid } from "../../app/core/utilities";
import { useTranslation } from "react-multi-lang";
import { RoundedAccordion, RoundedAccordionSummary } from "../../app/components/StyledComponents/StyledComponents";
import { addArtists } from "../../app/core/store/features/artist/artistSlice";
import ArtistItem from "../../app/components/ArtistItem/ArtistItem";


export default function Admin() {

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const axiosSecure = useAxiosSecure();
    const theme = useTheme();
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const restaurants = useSelector((state: RootState) => [...state.restaurant.restaurants]);
    const festivals = useSelector((state: RootState) => [...state.festival.festivals]);
    const artists = useSelector((state: RootState) => [...state.artist.artists]);

    const t = useTranslation();
    const dispatch = useDispatch();

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        async function apiCall() {
            let response = await axiosSecure.get(`/api/restaurant`, true);
            dispatch(addRestaurants(response.data));
        }

        apiCall();
    }, [])


    useEffect(() => {
        async function apiCall() {
            let response = await axiosSecure.get(`/api/festival`, true);
            dispatch(addFestivals(response.data));
        }

        apiCall();
    }, [])


    useEffect(() => {
        async function apiCall() {
            let response = await axiosSecure.get(`/api/artist`, true);
            dispatch(addArtists(response.data));
        }

        apiCall();
    }, [])

    function handleCreateFestivalClick(event: any) {
        event.stopPropagation();
        dispatch(setPanelContext({
            action: 'CREATE',
            contextType: 'Festival',
            contextObject: {
                id: newGuid()
            }
        }));
    }

    function handleCreateRestaurantClick(event: any) {
        event.stopPropagation();
        dispatch(setPanelContext({
            action: 'CREATE',
            contextType: 'Restaurant',
            contextObject: {
                id: newGuid()
            }
        }));
    }

    function handleCreateArtistClick(event: any) {
        event.stopPropagation();
        dispatch(setPanelContext({
            action: 'CREATE',
            contextType: 'Artist',
            contextObject: {
                id: newGuid()
            }
        }));
    }

    return (
        <>
            <RoundedAccordion
                disableGutters
                elevation={0}
                defaultExpanded={true}
            >
                <RoundedAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={styles.item}
                    >

                        <Typography>
                            {t('pages.admin.labels.festivals')}
                        </Typography>

                        <AddIcon
                            fontSize="small"
                            onClick={handleCreateFestivalClick}
                        />
                    </Stack>

                </RoundedAccordionSummary>

                {!isMobile && <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, 270px)',
                        gridAutoFlow: 'row',
                        gridColumnGap: '8px',
                        gridGap: '8px'
                    }}
                >
                    {festivals.map((festival, index) => (
                        <FestivalItem
                            key={index}
                            festival={festival}
                        />
                    ))}
                </div>}

                {isMobile && <Stack>
                    {festivals.map((festival, index) => (
                        <FestivalItem
                            key={index}
                            festival={festival}
                        />
                    ))}
                </Stack>}

            </RoundedAccordion>

            <RoundedAccordion
                disableGutters
                elevation={0}
                defaultExpanded={true}
            >
                <RoundedAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={styles.item}
                    >

                        <Typography>
                            {t('pages.admin.labels.artists')}
                        </Typography>

                        <AddIcon
                            fontSize="small"
                            onClick={handleCreateArtistClick}
                        />
                    </Stack>

                </RoundedAccordionSummary>

                {!isMobile && <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, 270px)',
                        gridAutoFlow: 'row',
                        gridColumnGap: '8px',
                        gridGap: '8px'
                    }}
                >
                    {artists.map((artist, index) => (
                        <ArtistItem
                            key={index}
                            artist={artist}
                        />
                    ))}
                </div>}

                {isMobile && <Stack>
                    {artists.map((artist, index) => (
                        <ArtistItem
                            key={index}
                            artist={artist}
                        />
                    ))}
                </Stack>}

            </RoundedAccordion>

            <RoundedAccordion
                disableGutters
                elevation={0}
                defaultExpanded={true}
            >
                <RoundedAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={styles.item}
                    >
                        <Typography>
                            {t('pages.admin.labels.restaurants')}
                        </Typography>

                        <AddIcon
                            fontSize="small"
                            onClick={handleCreateRestaurantClick}
                        />
                    </Stack>

                </RoundedAccordionSummary>


                {!isMobile && <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, 270px)',
                        gridAutoFlow: 'row',
                        gridColumnGap: '8px',
                        gridGap: '8px'
                    }}
                >
                    {restaurants.map((restaurant, index) => (
                        <RestaurantItem
                            key={index}
                            restaurant={restaurant}
                        />
                    ))}
                </div>}

                {isMobile && <Stack>
                    {restaurants.map((restaurant, index) => (
                        <RestaurantItem
                            key={index}
                            restaurant={restaurant}
                        />
                    ))}
                </Stack>}

            </RoundedAccordion>

        </>
    );
}

const styles = {
    item: {
        width: '100%',
        height: '20px'
    }
}