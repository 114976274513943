import Box from '@mui/material/Box';
import { countries } from './countries';
import { ListItem, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { setLanguage } from '../../core/store/features/ui/uiSlice';



export interface LanguageSelectProps {
    text: string;
    open: boolean;
    onMouseOver: (value: boolean) => void;
    onMouseOut: (value: boolean) => void;
    onChange?: (value: string) => void;
}

export default function LanguageSelect(props: LanguageSelectProps) {

    const language = useSelector((state: RootState) => state.ui.language);
    const dispatch = useDispatch();

    const handleChange = (event: SelectChangeEvent) => {
        props?.onChange?.(event.target.value as string);
        dispatch(setLanguage(event.target.value as string));
    };

    const handleMouseOver = () => {
        props?.onMouseOver(true)
    };

    const handleMouseOut = () => {
        props?.onMouseOut(false)
    };

    return (
        <>
            <ListItem
                key={props.text}
                // onClick={props.onClick}
                sx={styles.container}
            >
                <ListItemButton
                    sx={styles.listItemButton}
                >
                    <Select
                        value={language}
                        onChange={handleChange}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        inputProps={{ IconComponent: () => null }}
                        sx={{
                            mr: props.open ? 2 : 'auto',
                            ...styles.select
                        }}
                        renderValue={(country) => (
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
                                alt=""
                            />
                        )}
                    >
                        {countries.map((country, index) => (
                            <MenuItem key={country.code} value={country.code}>
                                <Box sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {country.label}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>

                    {props.open && <ListItemText primary={props.text}
                        primaryTypographyProps={styles.listItemText}
                    />}
                </ListItemButton>
            </ListItem>
        </>
    );
}


const styles = {
    container: {
        // padding: '4px 10px'
    },
    select: {
        padding: '0px 1px',
        boxShadow: 'none',
        '.MuiOutlinedInput-input': {
            padding: 'unset !important'
        },
        '.MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    },
    listItemButton: {
        height: 56,
        // padding: '4px 12px',
        borderRadius: '4px',
    },
    listItemText: {
        fontWeight: '600',
        fontSize: "1.0rem"
    }
}