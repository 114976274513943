import { Accordion, AccordionSummary, TextField, Typography, styled } from "@mui/material";


export const RoundedAccordion = styled(Accordion)(() => ({
    marginBottom: '0.7rem',
    borderRadius: '0.6rem !important',
    '&:before': {
        display: 'none',
    },
    width: '100%',
}));

export const RoundedAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    color: 'black',
    borderRadius: '8px',
    '&.Mui-expanded': {
        background: theme.palette.primary.main,
        color: 'white'
    },
    '.MuiAccordionSummary-expandIconWrapper': {
        color: 'black !important'
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        color: 'white !important'
    }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    background: 'white',
    width: '100%'
}));


export const SmallBoldTypography = styled(Typography)(({ theme }) => ({
    fontSize: 'small',
    fontWeight: 'bold'
}));


export const BoldTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));
