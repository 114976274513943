import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/core/store/store';
import api from '../../app/core/api/axios';
import { useDispatch } from 'react-redux';
import { updateStripeOnboarded } from '../../app/core/store/features/auth/authSlice';
import { set } from 'lodash';

const StripeConnect = () => {
    const [stripeConnected, setStripeConnected] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    // const [stripeConnectedError, setStripeConnectedError] = React.useState('');
    const [stripeConnectLink, setStripeConnectLink] = React.useState('');

    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();

    const getStripeConnected = async () => {
        user?.stripeOnboarded ? setStripeConnected(true) : setStripeConnected(false);
        if (!user?.stripeOnboarded) {
            setIsLoading(true);
            const response = await api.get(`api/user/stripe/onboarding/${user?.id}`);
            const data = await response.data;
            setIsLoading(false);
            if (data.stripeOnboardingLink) {
                setStripeConnected(false);
                setStripeConnectLink(data.stripeOnboardingLink);
            } else {
                setStripeConnected(true);
                const userUpdated = { ...user };
                if (userUpdated) {
                    userUpdated.stripeOnboarded = true;
                }
                dispatch(updateStripeOnboarded(true));
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getStripeConnected();
    }, []);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center',
            }}
        >
            {!stripeConnected ? <div>
                Connect your Stripe account to get paid!
                <br />
                <br />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        // navigate to stripe connect link
                        window.location.href = stripeConnectLink;
                    }}
                    disabled={isLoading}
                >
                    {
                        isLoading
                            ? "Loading..."
                            : "Connect with Stripe"}

                </Button>

            </div>
                : <div>
                    Your Stripe account is now connected!
                </div>
            }
        </div>
    )
}

export default StripeConnect;