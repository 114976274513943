import { useEffect, useState } from "react";
import OrderItem from "../../app/components/OrderItem/OrderItem";
import useAxiosSecure from "../../hooks/useAxiosSecure";
import StackGrid from "react-stack-grid";
import { RootState } from "../../app/core/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addOrders, selectOrdersForCurrentRestaurant } from "../../app/core/store/features/order/orderSlice";
import { OrderStateEnum } from "../../app/shared/interfaces/order-state.interface";
import { Order } from "../../app/shared/interfaces/order.interface";
import { useTranslation } from "react-multi-lang";
import AlertDialog from "../../app/dialogs/AlertDialog/AlertDialog";
import { dayjs } from "../../app/core/utilities";


export default function Orders() {

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    // const [openAlert, setOpenAlert] = useState(false);
    // const [clickedOrder, setClickedOrder] = useState<Order | null>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);


    const cancelOrderMode = useSelector((state: RootState) => state.ui.cancelOrderMode);
    const orderSearch = useSelector((state: RootState) => state.ui.orderSearch);
    const orderDateRange = useSelector((state: RootState) => state.ui.orderDateRange);
    const orderStateIds = useSelector((state: RootState) => state.ui.orderStateIds);

    const orders = useSelector(selectOrdersForCurrentRestaurant).filter((o) => {
        const dateToCheckTimestamp = dayjs((o as Order).createdAt).valueOf();

        let [from, to] = getDateRange(orderDateRange)

        const startDateTimestamp = dayjs(from).startOf('day').valueOf()
        const endDateTimestamp = dayjs(to).endOf('day').valueOf()

        return dateToCheckTimestamp >= startDateTimestamp && dateToCheckTimestamp <= endDateTimestamp
            && orderStateIds.indexOf(o?.orderStateId as string) > -1;
    });

    const dispatch = useDispatch();
    const t = useTranslation();

    function getDateRange(dateRange: string) {
        switch (dateRange) {
            case "today": {
                var start = new Date();
                start.setUTCHours(0, 0, 0, 0);

                var end = new Date();
                end.setUTCHours(23, 59, 59, 999);

                let from = start.toISOString()
                let to = end.toISOString()
                return [from, to];
            }
            case "yesterday": {
                const base = dayjs()
                    .utc()
                    .subtract(1, "d");
                const start = base.startOf("day");
                const end = base.endOf("day");
                return [start.toISOString(), end.toISOString()];
            }
            case "this-week": {
                const base = dayjs().utc();
                const start = base.startOf("week");
                const end = base.endOf("week");
                return [start.toISOString(), end.toISOString()];
            }
            default: {
                var start = new Date();
                start.setUTCHours(0, 0, 0, 0);

                var end = new Date();
                end.setUTCHours(23, 59, 59, 999);

                let from = start.toISOString()
                let to = end.toISOString()
                return [from, to];
            }
        }
    }

    useEffect(() => {
        async function apiCall() {

            // var start = new Date();
            // start.setUTCHours(0, 0, 0, 0);

            // var end = new Date();
            // end.setUTCHours(23, 59, 59, 999);

            // let from = start.toISOString()
            // let to = end.toISOString()

            // let orderStateIds = [OrderStateEnum.IsCreated, OrderStateEnum.IsPreparing];
            let [from, to] = getDateRange(orderDateRange)

            let response = await axiosSecure.get(`/api/order?restaurantId=${auth?.user?.restaurantId}&dateRangeFrom=${from}&dateRangeTo=${to}&${orderStateIds.map((n, index) => `orderStateIds=${n}`).join('&')}`, true);

            dispatch(addOrders(response.data?.map((order: Order) => ({
                ...order,
                state: order?.orderStateId === OrderStateEnum.IsDone ? t('orderState.isDone') : (
                    order?.orderStateId === OrderStateEnum.IsPreparing ? t('orderState.isPreparing') : (order?.orderStateId === OrderStateEnum.IsCanceled ? t('orderState.isCanceled') : t('orderState.isCreated'))
                )
            }))));
        }

        apiCall();
    }, [orderDateRange, orderStateIds])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        let isOpen = auth?.restaurant?.isOpen;

        if (!isOpen) {
            navigate("/overview");
        }
    })

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    function orderIsDisabled(order: Order) {
        return order.orderNumber?.toLocaleLowerCase()?.indexOf(orderSearch) === -1
    }

    return (
        <>
            {<div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, 260px)',
                    gridAutoFlow: 'row',
                    gridColumnGap: '16px',
                    gridGap: '16px'
                }}
            >
                {orders.map((order, index) => (
                    <OrderItem
                        disabled={orderIsDisabled(order as Order)}
                        key={order?.id}
                        cancelMode={cancelOrderMode}
                        order={order as Order}
                    />
                ))}
            </div>}

            {/* {isMobile && <div
                style={{
                    overflow: "auto"
                }}
            >
                {orders.map((order, index) => (
                    <OrderItem
                        style={{
                            marginBottom: '16px'
                        }}
                        key={order?.id}
                        order={order as Order}
                        detailsClicked={handleOrderDetailsClicked}
                    />
                ))}
            </div>} */}
        </>
    );
}