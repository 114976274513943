import { ListItem, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import { useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { Stage } from "../../shared/interfaces/stage.interface";


export interface StageItemProps {
    stage: Stage;
    onClick: (e: any) => void;
    onDelete: (e: any) => void;
}

export default function StageItem(props: StageItemProps) {

    const theme = useTheme();
    const styles = makeStyles(theme);
    const t = useTranslation()
    const dispatch = useDispatch();


    function handleDeleteClick(event: any) {
        event.stopPropagation();
        props?.onDelete?.(props?.stage);
    }

    return (
        <ListItem sx={styles.container}
            onClick={(e) => props?.onClick?.(props?.stage)}
        >
            <Stack
                justifyContent="space-between"
            >
                <Typography><strong>{props?.stage?.name}</strong></Typography>
            </Stack>

            {/* {props?.festivalRestaurant?.isRecommended && <Typography>Recomended</Typography>} */}

            <DeleteIcon sx={styles.icon} onClick={handleDeleteClick} />
        </ListItem>
    );
}


const makeStyles = (theme: Theme) => {
    return {
        container: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: 'pointer',
            ':hover': {
                background: theme.palette.grey[100]
            }
        },
        icon: {
            cursor: 'pointer'
        }
    }
}