import { Alert, AlertTitle, Button, Divider, Stack, styled, Switch, SwitchProps, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Category } from "../../shared/interfaces/category.interface";
import { ExtraCategory } from "../../shared/interfaces/extra-category.interface";


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


export interface CategoryEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: Category | ExtraCategory;
    isExtraCategory: boolean;
    onChangeData: (data: any) => void;
    onDelete: (id: string) => void;
}

export default function CategoryEditor(props: CategoryEditorProps) {

    const [newName, setNewName] = useState<string>(props?.data?.name || "");
    const [isOneChoice, setIsOneChoice] = useState<boolean>(props?.isExtraCategory ? (!(props?.data as ExtraCategory)?.isOneChoice || false) : false);
    const [isMandatory, setIsMandatory] = useState<boolean>(props?.isExtraCategory ? ((props?.data as ExtraCategory)?.isMandatory || false) : false);
    const t = useTranslation();

    const [nameError, setNameError] = useState("");
    

    useEffect(() => {
        // update data
        setNewName(props?.data?.name || "");
        setIsOneChoice(props?.isExtraCategory ? (!(props?.data as ExtraCategory)?.isOneChoice || false) : false)
        setIsMandatory(props?.isExtraCategory ? ((props?.data as ExtraCategory)?.isMandatory || false) : false)
    }, [props?.data])


    useEffect(() => {
        if ('isStandingOffer' in props?.data) {
            if (props?.isExtraCategory) {
                props?.onChangeData({
                    isValid: validateForm(),
                    id: props?.data?.id,
                    isStandingOffer: props?.data?.isStandingOffer,
                    name: newName,
                    isOneChoice: !isOneChoice,
                    isMandatory: isMandatory
                })
            } else {
                props?.onChangeData({
                    isValid: validateForm(),
                    id: props?.data?.id,
                    isStandingOffer: props?.data?.isStandingOffer,
                    name: newName
                })
            }
        } else {
            if (props?.isExtraCategory) {
                props?.onChangeData({
                    isValid: validateForm(),
                    id: props?.data?.id,
                    name: newName,
                    isOneChoice: !isOneChoice,
                    isMandatory: isMandatory
                })
            } else {
                props?.onChangeData({
                    isValid: validateForm(),
                    id: props?.data?.id,
                    name: newName
                })
            }
        }
    }, [newName, isOneChoice, isMandatory])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!newName) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        return isValid;
    };

    function handleChange(event: any) {
        setNewName(event.target.value);
    }

    function handleDeleteCategory() {
        props?.onDelete(props?.data?.id);
    }

    function handleOneChoiceChange(event: any) {
        setIsOneChoice(event.target.checked)
    }

    function handleMandatoryChange(event: any) {
        setIsMandatory(event.target.checked)
    }

    return (
        <Stack
            spacing={2}
        >
            <Typography><strong>{t('containers.editors.category.title')}</strong></Typography>
            <TextField
                fullWidth
                sx={{
                    borderStyle: 'dashed',
                    borderRadius: '8px'
                }}
                value={newName}
                onChange={handleChange}
                error={!!nameError}
                helperText={nameError}
                autoComplete='off'
            />

            {props?.isExtraCategory &&
                <>
                    <Stack
                        direction="row"
                        alignItems="start"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Typography><strong>{t('containers.editors.category.labels.isOneChoice')}</strong></Typography>
                        <IOSSwitch checked={isOneChoice} onChange={handleOneChoiceChange} />
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="start"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Typography><strong>{t('containers.editors.category.labels.isMandatory')}</strong></Typography>
                        <IOSSwitch checked={isMandatory} onChange={handleMandatoryChange} />
                    </Stack>

                    <Alert severity="info"
                        sx={{
                            marginTop: '10px',
                            whiteSpace: 'pre-line'
                        }}
                    >
                        <AlertTitle><strong>{t('containers.editors.category.labels.oneChoice')}</strong><br /></AlertTitle>
                        {t('containers.editors.category.labels.oneChoiceDescription')}

                    </Alert>
                </>
            }

            {props?.action === 'UPDATE' && <>
                <Divider />

                <Button
                    color="black"
                    variant="text"
                    onClick={handleDeleteCategory}
                >
                    {props?.isExtraCategory ? t('containers.editors.category.actions.extraCategory.delete') : t('containers.editors.category.actions.category.delete')}
                </Button>
            </>}

        </Stack>
    );
}