import { Box, Button, Card, CardContent, CircularProgress, Divider, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Order } from "../../shared/interfaces/order.interface";
import { useTranslation } from "react-multi-lang";
import { OrderStateEnum } from "../../shared/interfaces/order-state.interface";
import RejectOrderDialog, { RejectOrderDialogResult } from "../../dialogs/RejectOrderDialog/RejectOrderDialog";
import useAxiosSecure from "../../../hooks/useAxiosSecure";
import { updateOrder } from "../../core/store/features/order/orderSlice";
import { useDispatch } from "react-redux";


export interface OrderItemProps {
    style?: any;
    cancelMode: boolean;
    disabled: boolean;
    order: Order;
}

export default function OrderItem(props: OrderItemProps) {

    const [actionCountdown, setActionCountdown] = useState<number | null>(null);
    const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
    const [cancelOrderReason, setCancelOrderReason] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const axiosSecure = useAxiosSecure();
    const dispatch = useDispatch();
    const theme = useTheme();
    const styles = makeStyles(theme);
    const t = useTranslation()


    useEffect(() => {
        async function sendAction() {
            console.log("SEND ACTION");
            setLoading(true);
            try {
                if (props?.cancelMode) {
                    await handleCancelOrderClicked({
                        ...props?.order,
                        orderStateReason: cancelOrderReason?.orderStateReason,
                        outOfStockMenuitemIds: cancelOrderReason?.outOfStockMenuitemIds
                    });
                } else {
                    await handleOrderActionClicked(props?.order);
                }
            } catch (e) {
                throw e;
            } finally {
                setLoading(false);
                setActionCountdown(null);
            }
        }

        if (actionCountdown === 0) {
            sendAction();
        }

        // exit early when we reach 0
        if (!actionCountdown) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setActionCountdown(actionCountdown - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add actionCountdown as a dependency to re-rerun the effect
        // when we update it
    }, [actionCountdown]);

    useEffect(() => {
        setActionCountdown(null)
    }, [props?.cancelMode]);


    function handleOrderAction() {
        // if is cancelMode, show dialog first
        if (props?.cancelMode && actionCountdown == null) {
            handleCancelAction()
        } else {
            toggleCountdown()
        }
    }

    function toggleCountdown() {
        if (props?.order?.orderStateId !== OrderStateEnum.IsPickedUp &&
            props?.order?.orderStateId !== OrderStateEnum.IsCanceled) {
            if (actionCountdown != null) {
                setActionCountdown(null);
            } else {
                setActionCountdown(3);
            }
        }
    }

    async function handleOrderActionClicked(order: Order) {
        let response: any = {
            data: null
        };

        switch (order?.orderStateId) {
            case OrderStateEnum.IsCreated: {

                response = await axiosSecure.put(`/api/order`, {
                    id: order?.id,
                    orderStateId: OrderStateEnum.IsPreparing
                }, false);

                break;
            }
            case OrderStateEnum.IsPreparing: {

                response = await axiosSecure.put(`/api/order`, {
                    id: order?.id,
                    orderStateId: OrderStateEnum.IsDone
                }, false);

                break;
            }
            case OrderStateEnum.IsDone: {

                response = await axiosSecure.put(`/api/order`, {
                    id: order?.id,
                    orderStateId: OrderStateEnum.IsPickedUp
                }, false);

                break;
            }
            case OrderStateEnum.IsPickedUp: {
                break;
            }
            default: {
                break;
            }
        }

        if (response?.data && response?.data?.length > 0) {
            dispatch(updateOrder(response?.data[0]));
        }
    }

    async function handleCancelOrderClicked(event: any) {
        // TODO: missing items
        setCancelOrderReason(null);
        let response = await axiosSecure.put(`/api/order`, {
            id: event?.id,
            orderStateId: OrderStateEnum.IsCanceled,
            orderStateReason: event.orderStateReason
        }, false);

        if (response?.data && response?.data?.length > 0) {
            dispatch(updateOrder(response?.data[0]));
        }
    }

    function handleCancelAction() {
        setOpenRejectDialog(true);
    }

    function handleConfirmClose(value: RejectOrderDialogResult) {
        setOpenRejectDialog(false);

        let orderStateReason = "";

        switch (value.rejectReason) {
            case "1":
                orderStateReason = t('MenuitemOutOfStock');
                break;
            case "2":
                orderStateReason = t('OverloadedService');
                break;
            case "3":
                orderStateReason = t('TechnicalReason');
                break;
            case "4":
                orderStateReason = t('Other');
                break;
            default:
                orderStateReason = t('Other');
                break;
        }

        if (value.rejectOrder) {
            setCancelOrderReason({
                orderStateReason,
                outOfStockMenuitemIds: value.outOfStockMenuitemIds
            });
            toggleCountdown();
            // let response: any = await axiosSecure.put(`/api/order`, {
            //     id: props?.data?.id,
            //     orderStateId: OrderStateEnum.IsCanceled,
            //     orderStateReason: orderStateReason
            // }, true);

            // if (response?.data && response?.data?.length > 0) {
            //     dispatch(updateOrder(response?.data[0]));
            //     dispatch(setPanelContext({
            //         action: 'VIEW',
            //         contextType: 'Order',
            //         contextObject: response?.data[0]
            //     }))
            // }
        }
    }

    function getButtonText() {

        if (props?.cancelMode) {
            return `${t('pages.orders.orderItem.actions.reject')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
        }
        else {
            switch (props?.order?.orderStateId) {
                case OrderStateEnum.IsCreated:
                    return `${t('pages.orders.orderItem.actions.accept')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
                case OrderStateEnum.IsPreparing:
                    return `${t('pages.orders.orderItem.actions.ready')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
                case OrderStateEnum.IsCanceled:
                    return `${t('pages.orders.orderItem.actions.details')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
                case OrderStateEnum.IsDone:
                    return `${t('pages.orders.orderItem.actions.pickUp')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
                case OrderStateEnum.IsPickedUp:
                    return `${t('pages.orders.orderItem.actions.done')}${actionCountdown ? "..." + actionCountdown.toLocaleString() : ''}`;
                default:
                    return t('pages.orders.orderItem.actions.details');
            }
        }
    }

    function getButtonColor() {

        /*
            - Objednávka príde, reštika vidí tlačidlo “Prijať objednávku”
            - Po prijatí objednávky, tlačidlo sa zmení na akciu “Pripravené”.
            - Po kliknutí na tlačidlo “Pripravené”, sa tlačidlo zmení na “Vyzdvihnuté”.
              V tomto bode sa sprístupní tlačidlo “Označiť ako vyzdvihnuté” aj hosťovi. Dovtedy je vyšedené. To znmená, že obe strany môžu objednávku označiť ako vyzdvihnutú. Je to na nich, kto to urobí skôr.
            - Po kliknutí na tlačidlo “Vyzdvihnuté”, sa objednávka dokončí / vybaví.
        */

        if (props?.cancelMode) {
            return "offWhite";
        }
        else {
            switch (props?.order?.orderStateId) {
                case OrderStateEnum.IsCreated:
                    return "ketchupLight";
                case OrderStateEnum.IsPreparing:
                    return "mustardLight";
                case OrderStateEnum.IsCanceled:
                    return "natural";
                case OrderStateEnum.IsDone:
                    return "greenLight";
                case OrderStateEnum.IsPickedUp:
                    return "greenLight";
                default:
                    return "ketchupLight";
            }
        }
    }

    function getExtraMenuitemsText(orderMenuitemExtraMenuitems: any) {
        return orderMenuitemExtraMenuitems.map((extraItem: any) => extraItem?.extraMenuitem?.name).join("\n")
    }

    return (
        <>
            {openRejectDialog && <RejectOrderDialog
                open={openRejectDialog}
                onClose={handleConfirmClose}
                title={t('RejectOrder')}
                content={t('RejectOrderMessage')}
                noLabel={t('dialogs.actions.keep')}
                yesLabel={t('dialogs.actions.reject')}
                order={props?.order}
            />}

            <Box sx={styles.wrapper}>
                {loading && <Box sx={styles.loading}>
                    <CircularProgress color="primary" />
                </Box>}

                <Card sx={{
                    ...styles.cardContainer,
                    ...(props?.disabled ? styles.disabled : {}),
                    ...props?.style
                }}>

                    <CardContent
                        sx={styles.cardContent}
                    >
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                        >
                            <Stack
                                direction="row"
                                alignItems="start"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ width: '100%' }}
                            >
                                <Typography
                                    sx={styles.bold24}
                                >
                                    {new Date(props?.order?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                </Typography>

                                <Typography
                                    sx={styles.bold24}
                                    color={theme.palette.primary.main}
                                >
                                    {props?.order?.orderNumber}
                                </Typography>
                            </Stack>

                            <Divider sx={{ width: '100%' }} />

                            {props?.order?.orderMenuitems?.map((orderItem, index1) =>
                                <Stack
                                    key={orderItem?.id}
                                    direction="row"
                                    alignItems="start"
                                    justifyContent="start"
                                    spacing={2}
                                    sx={{ width: '100%' }}
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={styles.amountContainer}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                lineHeight: '18px',
                                                fontWeight: 700
                                            }}
                                        >
                                            {orderItem.amount}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction="column"
                                        alignItems="start"
                                        justifyContent="start"
                                    >
                                        <Typography
                                            sx={styles.menuitemText}
                                        >
                                            {orderItem.menuitem.name}
                                        </Typography>

                                        <Typography
                                            component="pre"
                                            sx={styles.extraMenuitemText}
                                        >
                                            {getExtraMenuitemsText(orderItem?.orderMenuitemExtraMenuitems)}
                                        </Typography>

                                        {orderItem?.note && <>
                                            <Typography
                                                sx={styles.noteLabelText}
                                            >
                                                Poznámka
                                            </Typography>

                                            <Typography
                                                sx={styles.noteText}
                                            >
                                                {orderItem?.note}
                                            </Typography>
                                        </>}
                                    </Stack>
                                </Stack>
                            )}

                        </Stack>
                    </CardContent>

                    <div style={{ marginTop: 8 }}>
                        <Button
                            disabled={props?.disabled || props?.cancelMode && (props?.order?.orderStateId === OrderStateEnum.IsCanceled || props?.order?.orderStateId === OrderStateEnum.IsPickedUp)}
                            fullWidth
                            color={getButtonColor()}
                            variant="contained"
                            sx={styles.button}
                            onClick={handleOrderAction}
                        >
                            {getButtonText()}
                        </Button>
                    </div>
                </Card>
            </Box>
        </>
    );
}

const makeStyles = (theme: Theme) => {
    return {
        wrapper: {
            position: 'relative'
        },
        cardContainer: {
            borderRadius: "4px",
            width: 260,
            border: `1px solid ${theme.palette.greyLight.main}`,
            textAlign: "center",
            padding: "16px",
            marginBottom: 'auto ',
            display: 'flex',
            justiyContent: 'space-between',
            flexDirection: 'column',
            boxShadow: 'none',
            ':hover': {
                boxShadow: 15,
                cursor: 'pointer'
            }
        },
        disabled: {
            opacity: 0.4
        },
        loading: {
            position: "absolute",
            height: '100%',
            width: '100%',
            zIndex: 1,
            backdropFilter: 'blur(3px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent'
        },
        button: {
            padding: '16px 8px',
            borderRadius: '4px'
        },
        cardContent: {
            padding: 0,
            // height: "100%"
        },
        bold24: {
            fontSize: 24,
            fontWeight: 900
        },
        amountContainer: {
            borderRadius: '4px',
            width: 24,
            height: 24,
            border: `1px solid ${theme.palette.greyLight.main}`,
            background: theme.palette.offWhite.main
        },
        menuitemText: {
            fontSize: 14,
            lineHeight: '21px',
            fontWeight: 700,
            color: theme.palette.black.main
        },
        extraMenuitemText: {
            textAlign: "start",
            fontSize: 14,
            lineHeight: '21px',
            fontWeight: 500,
            color: theme.palette.greyMedium.main
        },
        noteLabelText: {
            fontSize: 14,
            lineHeight: '21px',
            fontWeight: 700,
            color: theme.palette.ketchup.main
        },
        noteText: {
            fontSize: 14,
            lineHeight: '21px',
            fontWeight: 500,
            textAlign: 'start'
        }
    }
};
