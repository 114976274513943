import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface DeleteSafeDialogProps {
    open: boolean;
    noLabel: string;
    yesLabel: string;
    title: string;
    content: string;
    confirmPhrase: string;
    onClose: (value: boolean) => void;
}

export default function DeleteSafeDialog(props: DeleteSafeDialogProps) {

    const { onClose, open } = props;
    const [confirmationValue, setConfirmationValue] = useState('');
    const t = useTranslation();
    

    const handleYesClose = () => {
        onClose(true);
        setConfirmationValue('');
    };

    const handleNoClose = () => {
        onClose(false);
        setConfirmationValue('');
    };

    const handleConfirmationValueChange = (e: any) => {
        setConfirmationValue(e.target.value);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>{props?.title}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>
                        {props?.content || 'Do you really want delete?'}
                    </DialogContentText>

                    <TextField
                        variant="outlined"
                        label={`${t('dialogs.contents.typePhrase')} '${props?.confirmPhrase}' ${t('dialogs.contents.forApprovePhrase')}` || `${t('dialogs.contents.typePhrase')} 'DELETE' ${t('dialogs.contents.forApprovePhrase')}`}
                        value={confirmationValue}
                        onChange={handleConfirmationValueChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="black" size="small" onClick={handleNoClose}>{props?.noLabel || 'No'}</Button>
                <Button variant='contained' size="small" disabled={confirmationValue?.toLocaleLowerCase() !== props?.confirmPhrase?.toLocaleLowerCase()} onClick={handleYesClose}>{props?.yesLabel || 'Yes'}</Button>
            </DialogActions>
        </Dialog>
    );
}